import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme=>({

    header:{        
           '@media (max-width : 1500px)':{
                //height: '50%'        
            },
           '@media (max-width : 1024px)':{
                //height: '41%'        
            },
           '@media (max-width : 768px)':{
                //height: '23%'        
            },
            '@media (max-width : 750px)':{
                marginBottom: "10px",       
            },
           '@media (max-width : 460px)':{
                //height: '19%',
                top: '-1%',        
            },
    },
    titleHeader:{
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        textAlign: 'center',
        color:"black",
        backgroundColor:"#ffffff",
       '@media (max-width: 2000px)' :{  //web
            padding: "1% 0%",          
            fontSize: "1.5rem",
        },
       '@media (max-width:1566px)':{ // web

        },
       '@media (max-width:1024px)':{ // web

        },
       '@media (max-width:768px)':{ //ipad

        },
       '@media (max-width:460px)':{ //mobile
            padding: "1% 0%",          
            fontSize: "1rem",            
        },
       '@media (max-width:320px)':{ //mobile
            padding: "1% 0%",          
            fontSize: "1rem",            
        }        
    },
    subtitle:{          
        textAlign: 'center',
        // fontWeight: 600,
        fontFamily: 'sans-serif',
        color:"black",
        letterSpacing: '0.1px',
        //textShadow: '2px 3px 9px #adadad',
        transition: '0.4s ease-out',
       '@media (max-width: 2000px)' :{  //web
            padding: "2% 4% 1% 7%",          
            fontSize: "2rem",            
        },
       '@media (max-width:1566px)':{ // web
            padding: "2% 0% 0% 2%",
        },
       '@media (max-width:1024px)':{ // web
            padding: "2% 0% 0% 3%",
            fontSize: "1.5rem",
        },
       '@media (max-width:768px)':{ //ipad
            fontSize: "1rem",
        },
       '@media (max-width:460px)':{ //mobile
            paddingTop: "4%",
            color:"#fff",
            fontSize: "1rem",
            textShadow: '0px 0px 0px',
        }
    },
    description:{          
        textAlign: "center",
        fontFamily: 'sans-serif',
        color:"black",
        padding: "11px",
       '@media (max-width: 2560px)' :{  //web
            fontSize: "1.5rem",            
        },
       '@media (max-width:1440px)':{ // web
            fontSize: "1.3rem",
        },
       '@media (max-width:1025px)':{ // web
            fontSize: "1rem",
        },
       '@media (max-width:768px)':{ //ipad
            fontSize: "0.9rem",
        },
       '@media (max-width:415px)':{ //mobile
            fontSize: "0.8rem",
        },
    },
}));

