import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme=>({
    colorComponente:{
        backgroundColor: 'white',
        alignItems: "center",
        flexFlow: "row-wrap",
        padding:0,
    },

    gridList: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        // maxHeight: 570,
    },     

    rootLista: {
        width: '100%',
        maxWidth: '100%',
    }, 

    colorIcon:{
        color: 'black',
    },

    colorChip:{
        backgroundColor: '#1C4E78',
        color:'white'
    },
}))