import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    
    AlignTable: {
        textAlign: "center",
        marginBottom: "1%",
    },

    icon: {
        marginRight: 7,
    },
    
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
}));
