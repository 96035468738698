import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        boxSizing: "border-box",
        marginTop: 30,
        "&:after": {
            display: "table",
            clear: "both",
        },          
       '@media (max-width : 768px)':{
            display: "flex"
        },    
       '@media (max-width : 460px)':{
            display: "table",
            position: "fixed"
        },
    },
    column: {
        float: "left",
        padding: "10px",
        margin: 25,
       '@media (max-width : 1024px)':{
            width: "32%",     
        },
       '@media (max-width : 768px)':{
            display: "table",
            margin: 6,      
            marginBottom: "20px",
            width: "45%",
        },
    },
    cardContainer: {
        background: 'white',
        boxShadow: '0px 5px 6px 1px rgb(72 72 72 / 29%)',
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
        width: 318,
        height: "auto",
        alignItems: "center",
        borderRadius: 10,
        flexFlow: "row-wrap",
        position: "relative",
        transform: "translate(-50px, -50px)",
        top: "50%",
        left: "50%",
        paddingBottom: 15,
       '@media (max-width : 2000px)':{
            left: "20%",
            transform: "translate(90%, 50%)",
        },
       '@media (max-width : 1440px)':{
            transform: "translate(50%, 30%)",
            left: "50%",
        },
       '@media (max-width : 1280px)':{
            transform: "translate(25%, 30%)",    
        },
       '@media (max-width : 1024px)':{
            transform: "translate(0%, 30%)",    
        },
    },
    cardTitle: {
        padding: 0,
        marginBottom: -2,
        fontFamily: "sans-serif",
        fontWeight: 600,
        fontSize: '1.1rem',
        textAlign: "center",
       '@media (max-width : 1024px)':{
            fontSize: 15   
        },
       '@media (max-width : 768px)':{
            height: '23%'        
        },
       '@media (max-width : 460px)':{
            height: '19%',
            top: '-1%',        
        },
    },
    registerImage: {
        width: 80,
        height: 80,
        marginTop: 10,
       '@media (max-width : 1024px)':{
            width: 50,
            height: 50,
        },
       '@media (max-width : 768px)':{
            height: '23%'        
        },
       '@media (max-width : 460px)':{
            height: '19%',
            top: '-1%',        
        },
    },
    listItem:{
        paddingTop: 3,
        paddingBottom: 3,
        width: "100%",
        "&:hover":{
            textDecoration: "none",
            backgroundColor: "rgba(82, 174, 255, 0.1)",
        }
    },
    listItemIcon:{
        minWidth: 40,
    },
    listButton:{
        margin: "3% 0% 3% 0%",
       '@media (max-width : 1024px)':{
            width: 227,
            height: '23%',
            fontSize: 15
        },
       '@media (max-width : 768px)':{
            height: '23%',  
            // width: 227,     
        },
       '@media (max-width : 460px)':{
            height: '19%',
            top: '-1%',   
            // width: 227,     
        },
    },
    cardContent: {
        display: "contents",
    },
    icon:{
        color: '#066BBD',
        marginRight: 20,
        width: 33
    },
    lineaDegradada:{
        position: "relative",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 3,
        background: `rgb(2,0,36)`,
        background: `linear-gradient(344deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)`,
    },
    lineaDegradadaDivider:{
        background: "black",
        display: "inline-flex",
        height: 2,
        left: 0,
        marginTop: "1%",
        marginBottom: "3%",
        opacity: 0.1,
        width: "80%",
    },
    cardContainerMenu: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-around",
        alignItems: "stretch",
        maxWidth: "1150px",
        margin: "0 auto",
        padding: "20px 0",
        marginTop: 0,
        paddingTop: "20px",
        "@media (max-width:768px)": {
            //mobile
            paddingTop: "0px",
          },
      },
      cardContainerMenuLarge: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-around",
        alignItems: "stretch",
        maxWidth: "1500px",
        margin: "0 auto",
        padding: "20px 0",
        marginTop: 0,
        paddingTop: "20px",
        "@media (max-width:768px)": {
            //mobile
            paddingTop: "0px",
          },
      },
      card: {
          display: "initial",
          width: "355px",
          background: "#fff",
          borderRadius: "20px",
          boxShadow: "0px 5px 6px 1px rgb(72 72 72 / 29%)",
          padding: "10px",
          textAlign: "center",
          marginBottom: "2%",
          margin: "20px"         
      }

     /*  .card {
        background: #fff;
        border-radius: 10px;  
        box-shadow: 0px 5px 6px 1px rgb(72 72 72 / 29%);
        padding: 10px;
        text-align: center;
        margin-bottom: 2%;
        margin: 20px
      } */

}));