import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, Drawer, CssBaseline, AppBar, Toolbar, Typography, Button, Divider, Icon, Fab, Box } from '@material-ui/core';
// import EventIcon from '@mui/icons-material/Event';
import EventIcon from '@material-ui/icons/Event';
import Computer from '@material-ui/icons/Computer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import PrivateRoute from 'common/PrivateRoute';
import Home from 'page/Home/';
import Footer from 'page/Home/Footer2';
import CertificacionDeAfiliacionAPartidoPolitico from 'page/CertificacionDeAfiliacionAPartidoPolitico';
import CertificacionDeGoceDeSusDerechosPoliticos from 'page/CertificacionDeGoceDeSusDerechosPoliticos';
import ConstanciaDeInscripcionComoDirectivo from 'page/ConstanciaDeInscripcionComoDirectivo';
import ConstanciaCandidatoEleccionPopular from 'page/ConstanciaCandidatoEleccionPopular';
import ConstanciaDirectivoMandatarioOrganizacionPolitica from 'page/ConstanciaDirectivoMandatarioOrganizacionPolitica';
import ConstanciaAfiliacionAPartidosPoliticos from 'page/constanciaAfiliacionAPartidosPoliticos';
//Solicitudes de Adherentes y Afiliados
import SolicitudesAdhesion from 'page/OrganizacionesPoliticas/SolicitudAdhesion';
import SolicitudesAfiliacion from 'page/OrganizacionesPoliticas/SolicitudAfiliacion';
//Solicitudes de Hojas de Adhesion y Afiliacion
import SolicitudDeHojasDeAfiliacion from 'page/RegistroDeCiudadanos/SolicitudHojasAfiliacion';
import SolicitudDeHojasDeAdhesion from 'page/RegistroDeCiudadanos/SolicitudHojasAdhesion';
/* Reportes de Organizaciones Politicas */
import ReportesRenuncias from 'page/ReportesOrganizacionesPoliticas/ReportesRenuncias';
import ReportesAsambleas from 'page/ReportesOrganizacionesPoliticas/ReportesAsamblea';
//Reportes de Asambleas
//import ReportesAsambleas from 'page/OrganizacionesPoliticas/ReportesAsamblea';

import AsociarUsuarios from 'page/AsociarUsuarios';
import Empadronamiento from 'page/Empadronamiento';
import CitaEmpadronamientoActualizacion from 'page/CitaEmpadronamientoActualizacion';


import PerfilDelUsuario from 'page/UserProfile';
import EstadisticasDeAfiliados from 'page/Estadisticas/EstadisticasDeAfiliados';
import EstadisticasDeEmpadronados from 'page/Estadisticas/EstadisticasDeEmpadronados';
import InformacionGeneral from 'page/MiOrganizacionPolitica';
import PersonAddIcon from '@material-ui/icons/PersonAdd';


//import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';


import CitaDeEmpadronamiento from 'page/CitaDeEmpadronamiento';

import Login from 'page/Security/Login/';
import PaginaEnMantenimiento from 'page/PageInMaintenance'
import Signup from 'page/Security/Signup/';
import ValidarCertificacion from 'page/ValidarCertificacion/'
import ValidarSolicitud from 'page/ValidarSolicitud/'
import ValidarDocumentsGeo from 'page/ValidarDocumentsGeo'
import Profile from 'page/Security/Profile/';
import CambiarContrseña from '../../page/UserProfile/CambiarContreña/Form'
import CambiarContrseñaSinCode from 'page/UserProfile/CambiarContreñaSinCode/Form'
import MensajeConfirmacionCambioContraseña from '../../page/UserProfile/MensajeConfirmacionCambioContraseña'
import ObtenerCodigoCambiarContraseña from '../../page/UserProfile/ObtenerCodigo'
// publico
import ObtenerCorreoUsuario from 'page/Security/Login/CambiarContraseña/ObtenerCorreo'
import ObtenerCodigoExterno from 'page/Security/Login/CambiarContraseña/ObtenerCodigo'
import CambiarContraseñaExterno from 'page/Security/Login/CambiarContraseña/CambiarContreña'
import MensajeConfirmacionCambioContraseñaExterno from 'page/Security/Login/CambiarContraseña/MensajeConfirmacionCambioContraseña';
import ConsultaHojaAdhesionAfiliacion from 'page/RegistroDeCiudadanos/ConsultaHojaAdhesiónAfiliación';
import ConsultaConstanciaAdhesionAfiliacion from 'page/RegistroDeCiudadanos/ConsultaConstanciaAdhesiónAfiliación';

import { useStyles } from './Style';
import VerticalMenu from 'component/VerticalMenu';
import { connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import UserAccount from 'page/Security/Login/FormElements/UserAccount';
import { useHistory } from "react-router-dom";
import { publicMenu } from 'constant/index';
import { HelpOutline } from '@material-ui/icons';

const Menu = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    var authenticated = props.authenticated;
    const [open, setOpen] = React.useState(false);
    let history = useHistory();

    const onLogout = () => {
        let userAccount = new UserAccount();
        userAccount.logout();
        props.LOGOUT();
        props.SET_MENU(publicMenu);
        history.push("/login", {})
    }

    const handleDrawerClose = (props) => {
        setOpen(false);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    }

    return (

        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" component="nav"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                <Toolbar variant='regular' className={classes.toolbar}>

                    {props.authenticated ? (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : ""}
                    <Typography variant="h6" className={classes.title} >
                        <Link className={classes.appTitle} to="/">
                            <img className={classes.appIcon} src="/assets/WhiteIcon.svg" alt="fireSpot" />
                            {/* Portal Web de servicios del TSE */}
                            Portal Web TSE
                        </Link>
                    </Typography>
                    {props.authenticated ? (
                        <div>
                            <Button className={classes.button} color="inherit" to='/' component={Link}>
                                <Icon className={classes.icon}><Computer /></Icon>
                                <span className={classes.title_text}>Versión 2.4</span>
                            </Button>
                            <Button className={classes.button} color="inherit" href='https://ayudaportalweb.tse.org.gt'>
                                <Icon className={classes.icon}><HelpOutline /></Icon>
                                <span className={classes.title_text}>Ayuda</span>
                            </Button>
                            <Button className={classes.button} color="inherit" to='/' component={Link}>
                                <Icon className={classes.icon}><HomeIcon /></Icon>
                                <span className={classes.title_text}>Menú Principal</span>
                            </Button>
                            <Button className={classes.button} color="inherit" to='/PerfilDelUsuario' component={Link}>
                                <Icon className={classes.icon}><AccountCircle /></Icon>
                                <span className={classes.title_text}>Perfil</span>
                            </Button>
                            <Button className={classes.button} color="inherit"
                                onClick={onLogout} >
                                <Icon className={classes.icon}><LockIcon /></Icon>
                                <span className={classes.title_text}>Cerrar sesión</span>
                            </Button>
                        </div>
                    ) : (
                        <div>
                            {/* <Button className={classes.button} color="inherit" to='/citaDeEmpadronamiento' component={Link}>
                                <Icon className={classes.icon}><EventIcon /></Icon>
                                <span className={classes.title_text}>Cita de Empadronamiento</span></Button> */}
                            <Button className={classes.button} color="inherit" href='https://ayudaportalweb.tse.org.gt'>
                                <Icon className={classes.icon}><HelpOutline /></Icon>
                                <span className={classes.title_text}>Ayuda</span>
                            </Button>
                            <Button className={classes.button} color="inherit" to='/login' component={Link}>
                                <Icon className={classes.icon}>person</Icon>
                                <span className={classes.title_text}>Iniciar sesión</span></Button>
                            <Button className={classes.button} color="inherit" to="/signup" component={Link}>
                                <Icon className={classes.icon}><PersonAddIcon /></Icon>
                                <span className={classes.title_text}>Registro</span>
                            </Button>

                        </div>
                    )}
                </Toolbar>
            </AppBar>

            <Drawer className={classes.drawer} variant="persistent" anchor="left"
                open={open} classes={{ paper: classes.drawerPaper, }}
            >
                <div className={classes.drawerHeader}>
                    <Fab color="primary" aria-label="add" className={classes.fab}>
                        <img className={classes.image} src={"assets/navIcon.svg"} alt="Italian Trulli"></img>
                    </Fab>
                    <div className={classes.titleMenu}>Bienvenido</div>
                    <IconButton className={classes.iconMenu} onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <VerticalMenu />
                <Divider variant="middle" />
            </Drawer>

            <Box component="main" className={clsx(classes.content, { [classes.contentShift]: open, })}>
                <Toolbar />
                <Switch>
                    <Route exact path="/" render={(props) => <Home authenticated={authenticated} currentUser={props.currentUser} {...props} />}></Route>
                    <PrivateRoute exact path="/profile" authenticated={authenticated} currentUser={props.currentUser} component={Profile}></PrivateRoute>
                    <PrivateRoute exact path="/cambiarContraseña" authenticated={authenticated} currentUser={props.currentUser} component={CambiarContrseña}></PrivateRoute>
                    <PrivateRoute exact path="/changedPassword" authenticated={authenticated} currentUser={props.currentUser} component={CambiarContrseñaSinCode}></PrivateRoute>
                    <PrivateRoute exact path="/contraseñaActualizada" authenticated={authenticated} currentUser={props.currentUser} component={MensajeConfirmacionCambioContraseña}></PrivateRoute>
                    <PrivateRoute exact path="/cambiarContraseña/obtenerCodigo" authenticated={authenticated} currentUser={props.currentUser} component={ObtenerCodigoCambiarContraseña}></PrivateRoute>

                    <Route exact path="/cambiarContraseña/obtenerCorreoExterno" render={(props) => <ObtenerCorreoUsuario />}></Route>
                    <Route exact path="/cambiarContraseña/obtenerCodigoExterno" render={(props) => <ObtenerCodigoExterno />}></Route>
                    <Route exact path="/cambiarContraseña/nuevaContraseña" render={(props) => <CambiarContraseñaExterno />}></Route>
                    <Route exact path="/cambiarContraseña/contraseñaActualizada" render={(props) => <MensajeConfirmacionCambioContraseñaExterno />}></Route>

                    <PrivateRoute path="/certificacionDeAfiliacionAPartidoPolitico" authenticated={authenticated} currentUser={props.currentUser} component={CertificacionDeAfiliacionAPartidoPolitico}></PrivateRoute>
                    <PrivateRoute path="/certificacionDeGoceDeSusDerechosPoliticos" authenticated={authenticated} currentUser={props.currentUser} component={CertificacionDeGoceDeSusDerechosPoliticos}></PrivateRoute>
                    <PrivateRoute path="/constanciaDeInscripcionComoDirectivo" authenticated={authenticated} currentUser={props.currentUser} component={ConstanciaDeInscripcionComoDirectivo}></PrivateRoute>
                    <PrivateRoute path="/estadisticasDeAfiliados" authenticated={authenticated} currentUser={props.currentUser} component={EstadisticasDeAfiliados}></PrivateRoute>
                    <PrivateRoute path="/estadisticasDeEmpadronados" authenticated={authenticated} currentUser={props.currentUser} component={EstadisticasDeEmpadronados}></PrivateRoute>
                    <PrivateRoute path="/informacionGeneralMiOrganizacionPolitica" authenticated={authenticated} currentUser={props.currentUser} component={InformacionGeneral}></PrivateRoute>
                    <PrivateRoute path="/constanciaCandidatoEleccionPopular" authenticated={authenticated} currentUser={props.currentUser} component={ConstanciaCandidatoEleccionPopular}></PrivateRoute>
                    <PrivateRoute path="/constanciaDirectivoMandatarioOrganizacionPolitica" authenticated={authenticated} currentUser={props.currentUser} component={ConstanciaDirectivoMandatarioOrganizacionPolitica}></PrivateRoute>
                    <PrivateRoute path="/constanciaAfiliacionAPartidosPoliticos" authenticated={authenticated} currentUser={props.currentUser} component={ConstanciaAfiliacionAPartidosPoliticos}></PrivateRoute>
                    <PrivateRoute path="/asociarUsuarios" authenticated={authenticated} currentUser={props.currentUser} component={AsociarUsuarios}></PrivateRoute>
                    <PrivateRoute path="/solicitudAdhesion" authenticated={authenticated} currentUser={props.currentUser} component={SolicitudesAdhesion}></PrivateRoute>
                    <PrivateRoute path="/solicitudAfiliacion" authenticated={authenticated} currentUser={props.currentUser} component={SolicitudesAfiliacion}></PrivateRoute>
                    <PrivateRoute path="/solicitudHojasAfiliacion" authenticated={authenticated} currentUser={props.currentUser} component={SolicitudDeHojasDeAfiliacion}></PrivateRoute>
                    <PrivateRoute path="/solicitudHojasAdhesion" authenticated={authenticated} currentUser={props.currentUser} component={SolicitudDeHojasDeAdhesion}></PrivateRoute>
                    <PrivateRoute path="/solicitudesEmpadronamiento" authenticated={authenticated} currentUser={props.currentUser} component={Empadronamiento}></PrivateRoute>
                    <PrivateRoute path="/citaEmpadronamientoActualizacion" authenticated={authenticated} currentUser={props.currentUser} component={CitaEmpadronamientoActualizacion}></PrivateRoute>
                    {/* Reportes de Organizaciones Politicas */}
                    <PrivateRoute path="/reportesRenuncias" authenticated={authenticated} currentUser={props.currentUser} component={ReportesRenuncias}></PrivateRoute>
                    <PrivateRoute path="/report" authenticated={authenticated} currentUser={props.currentUser} component={ReportesAsambleas}></PrivateRoute>

                    {/*                     <PrivateRoute path="/solicitudHojasAfiliacionAdhesion" authenticated={authenticated} currentUser={props.currentUser} component={SolicitudDeHojasDeAfiliacionAdhesion}></PrivateRoute>
 */}
                    <PrivateRoute path="/PerfilDelUsuario" authenticated={authenticated} currentUser={props.currentUser} component={PerfilDelUsuario}></PrivateRoute>
                    <Route path="/citaDeEmpadronamiento" render={(props) => <CitaDeEmpadronamiento authenticated={authenticated} {...props} />}></Route>
                    <Route path="/signup" render={(props) => <Signup authenticated={authenticated} {...props} />}></Route>
                    <Route path="/certificacion/validar/" render={(props) => <ValidarCertificacion authenticated={authenticated} {...props} />}></Route>
                    <Route path="/certificacion/autorizacion/" render={(props) => <ValidarSolicitud authenticated={authenticated} {...props} />}></Route>
                    <Route path="/empadronamiento/constancia/" render={(props) => <ValidarDocumentsGeo tipo={'constancia'}  {...props} />}></Route>
                    <Route path="/empadronamiento/solicitud/" render={(props) => <ValidarDocumentsGeo tipo={'solicitud'}  {...props} />}></Route>
                    <Route path="/hojaAdhesionAfiliacion/consulta/" render={(props) => <ConsultaHojaAdhesionAfiliacion authenticated={authenticated} {...props} />}></Route>
                    <Route path="/constanciaAdhesionAfiliacion/consulta/" render={(props) => <ConsultaConstanciaAdhesionAfiliacion authenticated={authenticated} {...props} />}></Route>
                    <Route path="/login" render={(props) => <Login />}></Route>
                    {/* <Route path="/PageInMaintenance"    render={(props) => <PaginaEnMantenimiento/>}></Route> */}
                </Switch>
                {/* <Footer/>  */}
                {/*<Footer></Footer> */}
            </Box>

        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);