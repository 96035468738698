import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#EDEDED'
},
img: {
  margin: 'auto',
  display: 'block',
  maxWidth: '20%',
  // maxHeight: '100%',
},
}));
