
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        "&>h6":{
            fontSize: "1.5rem",
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    ); 
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


// PROPS
// HANDLECLOSE, OPEN, ESTATUS USUARIO
export default function DialogoPorEstado(props) {
    return (
        <div>
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                información para el usuario
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                    No se puede generar la solicitud porque se encuentra dentro de los estatus no disponibles para actualizar en el padrón,
                    por favor comunicarse a los teléfonos 22365055 / 22365087 con la sección de cambios y reposiciones.
                    </Typography>
                    <Typography gutterBottom>
                        Estatus actual: <b>{props.estadoDeCiudadanoEnPadron?.nombreStatus}</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                <Button autoFocus variant='outlined' onClick={props.handleClose} color="primary">
                    OK, he entendido
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}