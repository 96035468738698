import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    mainDialog: {
        "&>.MuiDialog-container": {
            "&>.MuiDialog-paper": {
                borderRadius: '15px'
            },        
        },    
    },

    modalContainer: {
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 70px'
    },

    headerSection: {
        textAlign: 'center'
    },

    titleModal: {
        fontWeight: 'bold',
        color: '#000',
        fontSize: '32px',
        margin: '10px',
        fontFamily: 'sans-serif'
    },

    subTitleModal: {
        fontWeight: 'bold',
        color: '#7C7C7C',
        fontSize: '24px',
        margin: '0px 0px 30px 0px',
        fontFamily: 'sans-serif',
        display: 'flex',
        justifyContent: 'center'
    },

    closeIconModal: {
        width: '40px !important',
        height: '40px !important',
        color: '#FF0000',
        position: 'absolute',
        top: '10px',
        right: '25px'
    },

    contentSectionModal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0px 0px 40px 0px'
    },

    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: '40px',
        padding: '10px 20px',
        filter: `drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))`,
    },

    buttonSearchContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '30px 0px 0px 0px',
        width: '100%'
    },

    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
    },

    textContainerCard: {
        display: 'flex',
        flexDirection: 'row'
    },

    textTitleCard: {
        fontWeight: 'bold',
        color: '#7C7C7C',
        fontSize: '18px',
        padding: '0px 10px 0px 0px',
        fontFamily: 'sans-serif',
        display: 'flex',
        justifyContent: 'center'
    },

    textSubtitleCard: {
        fontWeight: 'bold',
        color: '#000',
        fontSize: '18px',
        padding: '0px 0px 0px 0px',
        fontFamily: 'sans-serif',
        display: 'flex',
        justifyContent: 'center'
    },

    actionSection: {
        display: 'flex',
        flexDirection: 'row',
        padding: '30px 0px',
    },

    buttonCancelContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        margin: '0px 40px',
        width: '50%'
    },

    buttonConfirmContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '0px 40px',
        width: '50%'
    },

    buttonCancel: {
        width: '60%'
    },

    buttonSearch: {
        width: '40%',
        color: '#FFF !important',
        backgroundColor: '#1E88E5 !important'
    },

    buttonConfirm: {
        width: '60%',
        color: '#FFF !important',
        backgroundColor: '#1E88E5 !important'
    }
}));