import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { FormHelperText, FormControl, InputLabel, MenuItem, Select, Box } from '@material-ui/core';


const PhoneInputWithMaterialUI = (props) => {
    // const classes = useStyles();
    return (
        <FormControl 
            style={props.margin ? { margin: props.margin } : { margin: '0px' }}>
            <PhoneInput
                inputProps={{
                    name:props.name,
                }}
                value={props.value}  // Valor 
                country={props.inicialCountry} // "País default"
                excludeCountries={props.excludeCountries} // Para numero extranjero excluimos GT
                enableSearch={props.enableSearch} // Habilitar búsqueda dentro de la lista
                showDropdown={props.showDropdown} // Mostrar lista de paises al cargar componente
                specialLabel={props.label}
                searchPlaceholder={"Buscar"} // Texto del input de búsqueda
                onChange={props.handleChange}  // Evento
                // Ya esta adaptado para ser utilizado con MUI
                inputStyle={{
                    width: '100%',
                    padding: '12.5px 14px 12.5px 58px',
                    margin: '10px 0',
                    border: 'none',
                    borderBottom: '1px solid #949494',
                    borderRadius: '0px'
                }}
                // containerStyle={{ margin: '20px 0' }}
                // buttonStyle={{ padding: '10px' }}
                disabled={props.disabled}
            />
            {props.isError ? (
                <FormHelperText className="Mui-error" id="component-error-text">
                    {props.errorMessages} 
                </FormHelperText>
            ) :
                <FormHelperText id="component-helper-text">
                    {props.infoMessages} 
                </FormHelperText>
            }
        </FormControl>
    );
};

export default PhoneInputWithMaterialUI;