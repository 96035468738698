import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme=>({
    containerComponent:{
        border: '2px solid black',
        borderRadius: '10px'
    },
    
    colorComponente:{
        backgroundColor: 'white',
        alignItems: "center",
        flexFlow: "row-wrap",
       '@media (max-width:415px)':{ //mobile
            marginBottom: 10,
        },
       '@media (max-width:320px)':{ //mobile
            marginBottom: 10,
        }                
    },


    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: 200,
        },        
    },

    rootList: {
        flexGrow: 1,
    },
    
    rootLista: {
        width: '100%',
        maxWidth: '100%',
    }, 

    centrarBoton:{
        textAlign: 'center',
    },
    enlace:{
        paddingTop: '20px',
        color: 'black',
        textDecoration: 'none',
    }
}))