import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme=>({
    colorComponente:{
        backgroundColor: 'white',
        border: '1px solid  #cccccc ',
        borderRadius: '20px',                
        position: "relative",
        width: "100%",
        overflow: "auto",
        paddingLeft:0,
        paddingRight:0,             
    },
    
    colorBoton:{
        backgroundColor: "#1C4E78",
        color: "#fff",
        with: "auto",
    },

    marginBoton:{
        padding: '10px',
    },

    espacio: {
        paddingLeft: 20,
        textAlign: "left"
    },
        
    lineaDegradadaBottom:{
        position: "relative",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 4,
        background: `rgb(2,0,36)`,
        background: `linear-gradient(344deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)`,
    },
}))