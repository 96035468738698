import React, {useState, useEffect} from 'react';
import {Container, Grid, Button, Divider,} from '@material-ui/core/';
import { useStyles } from "./style";
import AlertMaterial from '@material-ui/lab/Alert';
import Form from 'component/Form/FormTwoColumns';
import {connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import ApiServices from 'service/ApiServices';
import Alert from 'react-s-alert';
import Mensaje from 'component/MensajeElement'
import { NavigateNext} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import LoadingProgress from 'component/Loading'

function VerificarIdentidad(props) {
    const classes = useStyles();
    const [controller,] = useState('user')
    const [open, setOpen] = useState(false)
    const [mensaje, setMensaje] = useState({tipoError:'', tipoMensaje:''})
    const [apiErrors,setApiErrors] = React.useState([]);
    const [elements, setElements] = React.useState({
        password: { idelement: "password",  value:'', label: "Introduce tu contraseña actual", pattern:"^[\\w_\\sÑñáéíóúÁÉÍÓÚ().!@#$%^&*/-]{6,20}$", validators: ['required'], errorMessages:['Alfanumérico y simbolos de 6-20 caracteres, sin espacios.'], isError:false, elementType:'password' },
    });  
    const [loading, setLoading] = useState(false)
    let history = useHistory();

    const VerificarIdentidadUsuario = async () =>{
        setLoading(true)
        const loginRequest = Object.assign({}, {"email": props.currentUser.email, "password": elements.password.value});
        // console.log(loginRequest)
        try{
            const response = await ApiServices.userSecurity.login(loginRequest)
            if (response.status != 401){
                history.push("/changedPassword",{})
                elements.password.value="";
                setElements({ ...elements });  
                setLoading(false)
            }   
        }catch(exception){
            if (exception.status===404) { Alert.warning("Intente de nuevo")}
            else {
                setOpen(true)
                setMensaje({tipoError:'error', tipoMensaje:'Oops! La contraseña no coincide'})                
                setLoading(false)
            }
        }                
    }

    const [buttonList,setButtonList]= React.useState({
        "register":{"label":"Siguiente","callback":VerificarIdentidadUsuario,"loading":false,"color":"#fff", "icon": <NavigateNext />, "width":"auto" ,"background":"#1C4E78"},
    });

    const handClose = (event, reason) =>{
        if (reason === 'clickaway') {return ;}
        setOpen(false)
    }

    const ObtenerCodigo = () =>{
        history.push("/cambiarContraseña/obtenerCodigo",{})
    }

    return (
        <div>
            <Container maxWidth="md" >        
                <Grid container >
                    <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                        <Mensaje type={mensaje.tipoError} content={mensaje.tipoMensaje} open={open} handClose={handClose} />
                    </Grid>
                    <Grid item xs={12} container direction="row" justify="center" alignItems="center" className={classes.centrarBoton}>
                        <Form   
                            elements= {elements} 
                            buttonList={buttonList}
                            apiErrors={apiErrors} 
                        />
                    </Grid>                    
                </Grid>
                <LoadingProgress open={loading} text="Verificando contraseña."/>
            </Container>
            <br/>
        </div>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(VerificarIdentidad);