import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme=>({
    root:{
        marginTop: 25,
        background: 'linear-gradient(45deg, #066bbd 30%, #63aaea 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
   },
    rootdiv: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',      
    },   
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },   
    buttonProgress: {
        color: blue[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    circularProgress: {
        color: blue[500],
    },
    buttonCircularProgress: {
        color: blue[500],
    },
    SpaceButton: {
        marginRight: 10,
    },
}))