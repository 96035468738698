import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FileCopyOutlined } from '@material-ui/icons';
import { Card } from '@material-ui/core';
import { connect } from 'react-redux';
import Alert from "react-s-alert";
/********** COMPONENTS **********/
import TitleWithIconBar from 'component/TitleWithIcon';
import LoadingSpinner from 'component/LoadingSpinner';
import AlertElement from 'component/AlertElement';
import Form from "component/Form/FormTwoColumns";
import NotAuthorized from 'common/NotAuthorized';
/********** SERVICES **********/
import ApiServices from 'service/ApiServices';
import { functions } from 'constant';
/********** REDUX **********/
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
/********** STYLES **********/
import { useStyles } from './style';

const ReportesRenuncias = (props) => {
/********** VARIABLES **********/
    const dataOPRedux = props.dataOP;
    const classes = useStyles();
    const controller = 'reportesGenerales';
    const dataOpRef = useRef(dataOPRedux);
    const [elementAlertModal, setElementAlertModal] = useState({openAlert: false, titleAlert: '', typeAlert: '' }),
    [authorized, setAuthorized] = useState(true),
    [loadingSpinner, setLoadingSpinner] = useState({openLoading: false, message: ''}),
    [elements] = useState({
        /* Inputs para Reporte Renuncias y Afiliaciones detallado */
        startDate: { 
            idelement: "startDate", value: null, label: "Fecha Inicial *", pattern: "^[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$", showInputControl: true,
            validators: 'required', errorMessages: ['Fecha requerida en formato: dd/MM/yyyy'], isError: false, elementType: 'date', disabled: false},
        finishDate: {
            idelement: "finishDate", value: null, label: "Fecha Final *", pattern: "^[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$", showInputControl: true,
            validators: 'required', errorMessages: ['Fecha requerida en formato: dd/MM/yyyy'], isError: false, elementType: 'date', disabled: false},
    }),
    [buttonList] = useState({
        "register": {"label": "Generar reporte", "callback": generateReport, "loading": false, "size": "medium", "color": "#fff", "icon": <FileCopyOutlined/>, "background": "", "showButton": true}
    });
/********** FUNCTIONS **********/
    const getOpData = useCallback(async () => {
        try {
            setLoadingSpinner({...loadingSpinner, openLoading: true, message: 'Obteniendo datos de la Organización...'});
            const hasPermission = await ApiServices.userSecurity.hasPermission("informacionGeneralMiOrganizacionPolitica","list");
            if (hasPermission.error) {
                setAuthorized(false);
                setLoadingSpinner({...loadingSpinner, openLoading: false})
                setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: 'Permisos Insuficientes, Contacte con soporte.', 
                    typeAlert: 'error' });
            } else {        
                const response = await ApiServices.informacionGeneralMiOrganizacion.list();
                if (response.error !== null) {
                    setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: response.error.message, 
                        typeAlert: 'error' });
                }
                else if (response.data !== "") {
                    props.INFORMATION_OP_SET_DATA(response.data);
                }
                setLoadingSpinner({...loadingSpinner, openLoading: false})
            }
        } catch (exception) {
            exception.status === 404 ? Alert.warning("Intente de nuevo") : Alert.warning("Intente de nuevo ");
            setLoadingSpinner({...loadingSpinner, openLoading: false})
        }
    },[]);

    useEffect(() => {
        if((Object.keys(dataOPRedux).length === 0)){
            getOpData();
        }
    },[]);

    useEffect(() => {
        dataOpRef.current = dataOPRedux;
    },[dataOPRedux]);

    /* Función para obtener reportes según los parametros seleccionados */
    async function generateReport(data){
        try {
            const valueOP = dataOpRef.current;
            setLoadingSpinner({ ...loadingSpinner, openLoading: true, message: 'Generando reporte...' }); 
            const hasPermission = await ApiServices.userSecurity.hasPermission(controller, "afiliadosORenunciasDetallado");
            if (hasPermission.error) {
                setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'warning', titleAlert: 'Permisos Insuficientes', 
                    mensajeAlert: 'No cuenta con permisos para ejecutar esta acción, consulte con soporte.'});
                setLoadingSpinner({ ...loadingSpinner, openLoading: false});
            } else {
                let dataSend = {tipoDeReporte: 2, fechaFinal: functions.dateStringFormat(data.finishDate), 
                    fechaInicio: functions.dateStringFormat(data.startDate), idOp: valueOP.idOrganizacion};
                let titleReport = 'Reporte de renuncias detallado';
                let response = await ApiServices.reportesGenerales.getInformeDetalladoAfRenun(dataSend);
                if(response.error !== null){
                    var messageError = JSON.parse(response.error.message);
                    setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'error', titleAlert: messageError.message });
                } else {
                    functions.downloadPDFFromStringBase64(response.data.data, titleReport);
                }
                setLoadingSpinner({ ...loadingSpinner, openLoading: false});
            }
        } catch (exception) {
            setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'error', titleAlert: 'Error al generar el reporte', 
                mensajeAlert: exception + ', consulte con soporte.' });
            setLoadingSpinner({ ...loadingSpinner, openLoading: false});
        }
    }

    /*  El método cierra el "AlertElement" que muestra los modales de alertas, se ejecuta desde el "AlertElement" */
    const handCloseModal = () => {
        setElementAlertModal({...elementAlertModal, openAlert: false})
    }
    
/********** RENDER **********/
    if (!authorized && !loadingSpinner.openLoading) {
        return <NotAuthorized />;
    }
    return (
        <div className={classes.root}>
            {loadingSpinner.openLoading && <LoadingSpinner open={loadingSpinner.openLoading} message={loadingSpinner.message}/>}
            {elementAlertModal.openAlert && <AlertElement typeAlert={elementAlertModal.typeAlert} openModal={elementAlertModal.openAlert} 
                title={elementAlertModal.titleAlert} handCloseModal={handCloseModal} />}
            <TitleWithIconBar title="Reporte de Renuncias" icon={"/menu/Constancia.png"} />
            <div className={classes.cardMainContainer}>
                <Card className={classes.cardContainer}>
                    <div className={classes.searchContainer}>
                        <Form elements={elements} buttonList={buttonList} />
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportesRenuncias);