import React, { useEffect, useState } from "react";
import { Container, Chip, Grid, List } from "@material-ui/core";
import Title from "component/TitleWithIcon";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  AccountCircle,
  Group,
  LocationOn,
  Phone,
  DynamicFeed,
  Mail,
  TextFormat,
  Update,
} from "@material-ui/icons";
//components
import { useStyles } from "./styles";
import Footer from "page/Home/Footer2";
/********** REDUX **********/
import mapStateToProps from './mapStateToProps';
import { connect } from "react-redux";


const InformacionGeneral = (props) => {
  const datos = props.dataOP
  const classes = useStyles();


  return (
    <div>
      <Container className={classes.contenedor} maxWidth="lg">
        <Grid container>
          <div
            style={{
              width: "100%",
              overflow: "auto",
              border: "1px solid  #cccccc",
              borderRadius: "20px",
              marginBottom: 10,
              backgroundColor:'white'
            }}
          >
            <Title title="Información General" icon="/assets/InformacionGeneral.png" />              
            <Grid container>
              {datos.base64 ? (
                <React.Fragment>
                  {/* primera columna */}
                  <Grid
                    item
                    md={8}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <div className={classes.gridList}>                    
                      <List className={classes.rootLista} dense>
                        <ListItem>
                          <ListItemIcon>
                            <Group />
                          </ListItemIcon>
                          <ListItemText primary="Nombre de la Organización Política: " />
                          <Chip label={datos.name} />
                        </ListItem>
                        {/* <ListItem>
                        <ListItemIcon>
                          <Filter1 />
                        </ListItemIcon>
                        <ListItemText primary="Código: " />
                        <Chip label="GN" />
                      </ListItem> */}
                        <ListItem>
                          <ListItemIcon>
                            <TextFormat />
                          </ListItemIcon>
                          <ListItemText primary="Siglas: " />
                          <Chip label={datos.siglas} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary="Departamento: " />
                          <Chip label={datos.departamento} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary="Municipio: " />
                          <Chip label={datos.municipio} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary="Dirección: " />
                          <Chip label={datos.direccion} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Phone />
                          </ListItemIcon>
                          <ListItemText primary="Teléfono: " />
                          <Chip label={datos.telefono} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Mail />
                          </ListItemIcon>
                          <ListItemText primary="Correo Electrónico: " />
                          <Chip label={datos.email} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AccountCircle />
                          </ListItemIcon>
                          <ListItemText primary="Nombre del Representante Legal: " />
                          <Chip label={datos.representanteLegal} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <DynamicFeed />
                          </ListItemIcon>
                          <ListItemText primary="Fase: " />
                          <Chip label={datos.fase} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Update />
                          </ListItemIcon>
                          <ListItemText primary="Estado: " />
                          <Chip label={datos.estado} />
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                  {/* segunda columna */}
                  <Grid
                    item
                    md={4}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <img
                      className={classes.Image}
                      // src="https://wikiguate.com.gt/wp-content/uploads/2015/04/GRAN-ALIANZA-NACIONAL.png"
                      src={`data:image/jpeg;base64,${datos.base64}`}
                      alt="Logotipo"
                    />
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* primera columna */}
                  <Grid
                    item
                    md={12}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <div className={classes.gridList}>
                      <List className={classes.rootLista} dense>
                        <ListItem>
                          <ListItemIcon>
                            <Group />
                          </ListItemIcon>
                          <ListItemText primary="Nombre de la Organización Política: " />
                          <Chip label={datos.name} />
                        </ListItem>
                        {/* <ListItem>
                        <ListItemIcon>
                          <Filter1 />
                        </ListItemIcon>
                        <ListItemText primary="Código: " />
                        <Chip label="GN" />
                      </ListItem> */}
                        <ListItem>
                          <ListItemIcon>
                            <TextFormat />
                          </ListItemIcon>
                          <ListItemText primary="Siglas: " />
                          <Chip label={datos.siglas} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary="Departamento: " />
                          <Chip label={datos.departamento} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary="Municipio: " />
                          <Chip label={datos.municipio} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary="Dirección: " />
                          <Chip label={datos.direccion} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Phone />
                          </ListItemIcon>
                          <ListItemText primary="Teléfono: " />
                          <Chip label={datos.telefono} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Mail />
                          </ListItemIcon>
                          <ListItemText primary="Correo Electrónico: " />
                          <Chip label={datos.email} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AccountCircle />
                          </ListItemIcon>
                          <ListItemText primary="Nombre del Representante Legal: " />
                          <Chip label={datos.representanteLegal} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <DynamicFeed />
                          </ListItemIcon>
                          <ListItemText primary="Fase: " />
                          <Chip label={datos.fase} />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Update />
                          </ListItemIcon>
                          <ListItemText primary="Estado: " />
                          <Chip label={datos.estado} />
                        </ListItem>
                      </List>
                    </div>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <div
              style={{
                bottom: 0,
                left: 0,
                width: "100%",
                height: 4,
                background: `rgb(2,0,36)`,
                background: `linear-gradient(344deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)`,
              }}
            ></div>
          </div>
        </Grid>
      </Container>

      {/* {datos.base64 ? (
        <div>
          <h1>Hola. Hay base 64</h1>
        </div>
      ) : (
        <div>
          <h1>Hola. No hay base 64</h1>
        </div>
      )} */}

      <div className="separateDiv">
        <Footer />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(InformacionGeneral);