import React from 'react'
import {Container, makeStyles, Chip, Grid, List ,
} from '@material-ui/core';

import ListItem from '@material-ui/core/ListItem';
import {ListItemIcon, ListItemText, Divider} from '@material-ui/core';
import {AccountCircle, Email, Filter1, VpnKey } from '@material-ui/icons';
import { useStyles } from "../UserInformation/UserInformationStyle";
import Title from 'component/TitleWithIcon';

export default function UserInformation (props) {
    const classes = useStyles()

    return (
        <Container maxWidth="md" className={classes.colorComponente}>        
            <Grid container >
                <Grid container item xs={12} direction="row" justify="center" alignItems="center">
                    <div className={classes.gridList} >
                    <Title title="Datos del Usuario" icon={"/assets/DatosUsuario.png"}/>    
                        <List className={classes.rootLista} dense>
                            <ListItem>
                                <ListItemIcon className={classes.colorIcon}> <Filter1 /> </ListItemIcon>
                                <ListItemText className={classes.colorIcon} primary="CUI del DPI: " />
                                <Chip className={classes.colorChip} label={props.DatosUsuario.code} />                                
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.colorIcon}> <AccountCircle /> </ListItemIcon>
                                <ListItemText className={classes.colorIcon} primary="Nombre completo: " />
                                <Chip className={classes.colorChip} label={props.DatosUsuario.name} />                                
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.colorIcon}> <Email /> </ListItemIcon>
                                <ListItemText className={classes.colorIcon} primary="Correo electrónico: " />
                                <Chip className={classes.colorChip} label={props.DatosUsuario.email} />                                
                            </ListItem>
                        </List>
                    </div>
                </Grid>
            </Grid>               
        </Container>       
    )
}