import React from "react";
import Title from 'component/TitleWithIcon';

//components
import MenuMiOrganizacionPolitica from './MenuMiOrganizacionPolitica'

export default function InformacionGeneral() {

  return (
    <div>
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <Title title="Mi Organización Política" icon={"/menu/miOrganizacionPolitica.png"}/>
      </div>

      <MenuMiOrganizacionPolitica />
    </div>
  );
}
