import React, { useState } from 'react';
import { Button, Card, Container, Dialog, Slide } from '@material-ui/core';
import { HighlightOffOutlined } from '@material-ui/icons';
import AlertMaterial from '@material-ui/lab/Alert'
/********** COMPONENTS **********/
import ModalConfirm from '../ModalConfirm';
import ResponseElement from 'component/MensajeElement';
import LoadingSpinner from 'component/LoadingSpinner';
import AlertElement from 'component/AlertElement';
import Form from "component/Form/FormTwoColumns";
/********** SERVICES **********/
import ApiServices from "service/ApiServices";
import { functions } from "constant/index";
/********** STYLES **********/
import { useStyles } from './Styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalNewCitizen({setOpenModal, openModal, modalElements, showList, correlativo}){
/********** VARIABLES **********/
    const classes = useStyles();
    const controller = 'buscarCiudadano';
    const [elementsAlertModal, setElementsAlertModal] = useState({ openModal: false, titleAlert: '', mensajeAlert: '', typeAlert: '' }),
        [dataCitizen, setDataCitizen] = useState({cui: '', firstName: '', secondName: '', firstLastName: '', secondLastName: ''}),
        [alertResponseElements, setAlertResponseElements] = useState({openAlert: false, typeAlert: '', message: ''}),
        [modalConfirmElements, setModalConfirmElements] = useState({title: '', subTitle: ''}),
        [loadingSpinner, setLoadingSpinner] = useState({openLoading: false, message: ''}),
        [errorElements, setErrorElements] = useState({openMessage: false, message: ''}),
        [dataElements, setDataElements] = useState({data: [], showData: false }),
        [showButtonConfirm, setShowButtonConfirm] = useState(true),
        [openConfirmDialog, setOpenConfirmDialog] = useState(false),
        [elementsAffiliate, setElementsAffiliate] = useState({
            noLinea: {
                idelement: "noLinea", value: "", margin: '40px 10px 0px 0px', label: "Ingrese número de línea *", validators: ["required"],
                pattern: "^[0-9]*$", errorMessages: ["Número de Línea requerido"], isError: false, elementType: "input", maxLengthTwo: 1,
                disabled: false, key: 15, keys: [15, 16],
            },
            cui: {
                idelement: "cui", value: "", margin: '40px 10px 0px 10px', label: "Ingrese número de CUI *", requiredLength: 13, validators: ["required"],
                isError: false, pattern: "^[0-9]*$", disabled: false, errorMessages: ["CUI requerido"], keys: [21, 22],
                elementType: "input", key: 21,
            },
            afiliateDate: {
                idelement: "afiliateDate", value: null,  label: "Fecha de Afiliación o Adhesión *", validators: ["required"],
                pattern: "^[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$", errorMessages: ['Fecha requerida en formato: dd/MM/yyyy'], isError: false, elementType: "date",
                margin: '40px 10px 0px 10px', key: 23, keys: [23, 24], maxDate: new Date()
            }
        });
    
/********** FUNCTIONS **********/
    /* Con esta función realizamos la busqueda de los datos del Ciudadano según su CUI */
    const searchDataCUI = async () => {
        try {
            setDataElements({...dataElements, data: [], showData: false});
            closeMessageErrorModal();
            if(elementsAffiliate.cui.value === '' || elementsAffiliate.noLinea.value === '' 
                || elementsAffiliate.afiliateDate.value === null){
                setElementsAlertModal({ ...elementsAlertModal, openModal: true, typeAlert: 'error', titleAlert: 'Faltan Campos Obligatorios', 
                    mensajeAlert: 'Por favor complete todos los campos requeridos.'});
            } else {
                setLoadingSpinner({...loadingSpinner, openLoading: true, message: 'Obteniendo datos del ciudadano...'});
                const hasPermission = await ApiServices.userSecurity.hasPermission(controller, 'view');
                if (hasPermission.error) {
                    setElementsAlertModal({ ...elementsAlertModal, openModal: true, typeAlert: 'warning', titleAlert: 'Permisos Insuficientes', 
                        mensajeAlert: 'No cuenta con Permisos para acceder a este modulo, consulte con soporte.'});
                    setLoadingSpinner({ ...loadingSpinner, openLoading: false});
                } else {
                    const cui = elementsAffiliate.cui.value;
                    let response = await ApiServices.buscarCiudadano.searchCitizen(cui);
                    if (response.error !== null) {
                        setElementsAlertModal({ ...elementsAlertModal, openModal: true, typeAlert: 'warning', titleAlert: response.error.message, 
                            mensajeAlert: ''});                          
                    } else {
                        let datosCiudadano = response.data.datosPadron;
                        var primerNombre = datosCiudadano.primerNombre ? datosCiudadano.primerNombre : '';
                        var segundoNombre = datosCiudadano.segundoNombre ? datosCiudadano.segundoNombre : '';
                        var primerApellido = datosCiudadano.primerApellido ? datosCiudadano.primerApellido : '';
                        var segundoApellido = datosCiudadano.segundoApellido ? datosCiudadano.segundoApellido : ''
                        let dataCard = [{
                            title: 'Nombres:', value: primerNombre + ' ' + segundoNombre}, 
                            {title: 'Apellidos:', value: primerApellido + ' ' + segundoApellido}];
                        setDataCitizen({...dataCitizen, cui: elementsAffiliate.cui.value, 
                            firstName: primerNombre, secondName: segundoNombre, 
                            firstLastName: primerApellido, secondLastName: segundoApellido});
                        setDataElements({...dataElements, data: dataCard, showData: true});
                    }
                    setLoadingSpinner({ ...loadingSpinner, openLoading: false});
                }
            }
        } catch (exception) {
            setElementsAlertModal({ ...elementsAlertModal, openModal: true, typeAlert: 'error', titleAlert: 'Error al obtener la información', 
                mensajeAlert: exception + ', consulte con soporte.' });
            setLoadingSpinner({ ...loadingSpinner, openLoading: false});
        }
    }

    /* Función para la busqueda de Status en el Padron de un ciudadano según su número de CUI */
    const validateCitizenCUI = async () => {
        try {
            setLoadingSpinner({...loadingSpinner, openLoading: true, message: 'Validando datos del ciudadano...'});
            let sendDataCitizen = {
                cui: dataCitizen.cui,
                primerNombre: (dataCitizen.firstName).toUpperCase(), 
                primerApellido: (dataCitizen.firstLastName).toUpperCase()
            }
            let response = await ApiServices.hojasAfiliacionYAdhesion.verificarCUI(sendDataCitizen);
            if (response.error !== null) {
                /*  Si el ciudadano presenta inconsistencias se abre el modal para confirmar si se quiere agregar */
                
                setLoadingSpinner({ ...loadingSpinner, openLoading: false});
                if(response.error.message === 'EL CIUDADANO SE ENCUENTRA CON STATUS FALLECIDO EN EL PADRÓN'){
                    setModalConfirmElements({...modalConfirmElements, title: response.error.message, subTitle: ''});
                    setShowButtonConfirm(false);
                } else {
                    setModalConfirmElements({...modalConfirmElements, title: response.error.message,
                        subTitle: 'El ciudadano presenta inconsistencias, ¿Seguro que desea agregarlo a la hoja?'});
                    setShowButtonConfirm(true);
                }
                setOpenConfirmDialog(true);
            } else {
                /* Si el CUI no tiene ninguna inconsistencia, se ejecuta el método para guardar al ciudadano en la hoja */
                setLoadingSpinner({ ...loadingSpinner, openLoading: false});
                addNewCitizen();
            }
        } catch (exception) {
            setElementsAlertModal({ ...elementsAlertModal, openModal: true, typeAlert: 'error', titleAlert: 'Error al obtener la información', 
                mensajeAlert: exception + ', consulte con soporte.' });
            setLoadingSpinner({ ...loadingSpinner, openLoading: false});
        }
    }

    /* Función para agregar un ciudadano a la Hoja en la que se esta trabajando */
    const addNewCitizen = async () => {
        try {
            handleCloseSendModal();
            setLoadingSpinner({...loadingSpinner, openLoading: true, message: 'Ingresando datos del ciudadano...'});
            let sendNewCitizen = {
                noLinea: elementsAffiliate.noLinea.value, 
                cui: dataCitizen.cui, 
                fechaAfiliacion: elementsAffiliate.afiliateDate.value,
                primerNombre: (dataCitizen.firstName).toUpperCase(), 
                segundoNombre: (dataCitizen.secondName).toUpperCase(),
                primerApellido: (dataCitizen.firstLastName).toUpperCase(),
                segundoApellido: (dataCitizen.secondLastName).toUpperCase()            
            }
            let response = await ApiServices.hojasAfiliacionYAdhesion.guardarAfiliado(correlativo, sendNewCitizen);

            if (response.error !== null) {
                setAlertResponseElements({...alertResponseElements, openAlert: true, typeAlert: 'error', 
                    message: response.error.message});
                setLoadingSpinner({ ...loadingSpinner, openLoading: false});
            } else {
                setAlertResponseElements({...alertResponseElements, openAlert: true, typeAlert: 'success', 
                    message: "El ciudadano con CUI " + elementsAffiliate.cui.value + " se agrego correctamente en la linea " + elementsAffiliate.noLinea.value});
                functions.clearElementValuesNoDate(elementsAffiliate, setElementsAffiliate);
                setDataElements({...dataElements, data: [], showData: false});
                setLoadingSpinner({ ...loadingSpinner, openLoading: false});
            }
        } catch (exception) {
            setElementsAlertModal({ ...elementsAlertModal, openModal: true, typeAlert: 'error', titleAlert: 'Error al obtener la información', 
                mensajeAlert: exception + ', consulte con soporte.' });
            setLoadingSpinner({ ...loadingSpinner, openLoading: false});
        }
    }

    /* Con esta función se cierra el modal y se cierra el mensaje de error, desde Cancelar o al presionar fuera del Modal */
    const handleCloseModal = () => {
        setOpenModal(!openModal);
        showList();
    }

    /* Cierra el mensaje de Error dentro del Modal */
    const closeMessageErrorModal = () => {
        setErrorElements({...errorElements, openMessage: false, message: ''})
    }

    /*  El método cierra el "ConfirmationElement" que muestra si un ciudadano presenta inconsistencias  */
    const handleCloseSendModal = () => {
        setOpenConfirmDialog(false);
    }

     /*  El método cierra el "AlertElement" que muestra los modales de alertas, se ejecuta desde el "AlertElement" */
     const handleCloseAlertModal = () => {
        setElementsAlertModal({ ...elementsAlertModal, openModal: false });
    }

    /*  El método cierra el modal "ResponseElement" de mensaje de alerta */
    const handleCloseResponseElement = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertResponseElements({...alertResponseElements, openAlert: false, typeAlert: '', message: ''});
    };

/********** RENDER **********/
    return(
        <Dialog className={classes.mainDialog} open={true} maxWidth={"lg"} TransitionComponent={Transition} keepMounted onClose={handleCloseModal}
            aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                {openConfirmDialog && <ModalConfirm open={openConfirmDialog} handleClose={handleCloseSendModal} handleConfirm={addNewCitizen}
                        titleModal={modalConfirmElements.title} subtitleModal={modalConfirmElements.subTitle} showButton={showButtonConfirm} />}
                {loadingSpinner.openLoading && <LoadingSpinner open={loadingSpinner.openLoading} message={loadingSpinner.message}/>}
                {elementsAlertModal.openModal && <AlertElement typeAlert={elementsAlertModal.typeAlert} openModal={elementsAlertModal.openModal} 
                    title={elementsAlertModal.titleAlert} mensaje={elementsAlertModal.mensajeAlert} handCloseModal={handleCloseAlertModal} />} 
                {alertResponseElements.openAlert &&  <ResponseElement type={alertResponseElements.typeAlert} content={alertResponseElements.message} 
                    open={alertResponseElements.openAlert} handClose={handleCloseResponseElement} hideDuration={3000} />}
                <div className={classes.modalContainer}>
                    <div className={classes.headerSection}>
                        <p className={classes.titleModal}>{modalElements.title}</p>
                        <p className={classes.subTitleModal}>{modalElements.subTitle}</p>
                        <HighlightOffOutlined onClick={() => handleCloseModal()}  className={classes.closeIconModal}/>
                    </div>
                    <div className={classes.contentSectionModal}>
                        <Form elements={elementsAffiliate} />
                        <div className={classes.buttonSearchContainer}>
                            <Button onClick={() => searchDataCUI()} className={classes.buttonSearch} variant="contained">
                                Buscar Ciudadano
                            </Button>
                        </div>
                        {dataElements.showData && (
                            <Card elevation={0} className={classes.cardContainer}>
                                {dataElements.data.map((item, index) => {
                                    return(
                                        <div key={index} className={classes.textContainerCard}>
                                            <p className={classes.textTitleCard}>{item.title}</p>
                                            <p className={classes.textSubtitleCard}>{item.value}</p>
                                        </div>
                                    )
                                })}
                            </Card>
                        )}
                        {errorElements.openMessage && (
                            <Container className={classes.errorContainer} maxWidth="sm">
                                <AlertMaterial variant="outlined" severity="error">
                                    {errorElements.message}
                                </AlertMaterial>
                            </Container>
                        )}
                    </div>
                    {dataElements.showData && (
                        <div className={classes.actionSection}>
                            <div className={classes.buttonCancelContainer}>
                                <Button className={classes.buttonCancel} color="secondary" variant='outlined' onClick={() => handleCloseModal()}>
                                    Cancelar
                                </Button>
                            </div>
                            <div className={classes.buttonConfirmContainer} onClick={() => validateCitizenCUI()}>
                                <Button className={classes.buttonConfirm} variant="contained">
                                    Confirmar
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
        </Dialog>
    )
}