import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootLogotipo: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  rootLista: {
    width: "100%",
    maxWidth: "100%",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  rootList: {
    flexGrow: 1,
  },
  appBar: {
    marginBottom: theme.spacing(2),
    position: "relative",
    backgroundColor: "#2196f3",
  },
  rootToolbar: {
    flexGrow: 1,
  },
  titulo: {
    color: "#ffffff",
    flexGrow: 1,
  },
  BordeListaGrupo: {
    border: "inset 3px black",
    height: 300,
  },
  gridList: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 570,
  },
  Image: {
    backgroundSize: "cover",
    position: "relative",
    maxWidth: 240,
    maxHeight: 240
    /* width: 240,
    height: 230, */
  },
  greyCard: {
    backgroundColor: "#f2f2f2",
    borderRadius: "15px",
    boxShadow: "0px 5px 6px 1px rgb(72 72 72 / 29%)",
  },
  contenedor: {
    // paddingTop: "2%",
    //borderRadius: "25px",
  },
}));
