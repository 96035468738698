import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    actionContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'center'
    },

    iconButtonContainer: {
        marginLeft: '10px',
        marginRight: '10px'
    },

    iconStyles: {
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#e8e8e8'
        }
    }
}));