import ApiGenericServicesData from "service/ApiGenericServicesData";

export default class BuscarCiudadano extends ApiGenericServicesData {
    constructor() {
        super("buscarCiudadano");
    }

    searchCitizen(cui) {
        return this.customGET("view/"+ cui);
    }
}
