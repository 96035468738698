export const actionNames = {
    // MENU SELECTED ITEMS
    SET_MENU_ITEM_GROUP: "SET_MENU_ITEM_GROUP",
    SET_MENU_ITEM: "SET_MENU_ITEM",

    //security
    SET_CURRENT_USER: "SET_CURRENT_USER",
    SET_MENU: "SET_MENU",
    LOGOUT: "LOGOUT",
    REGISTER: "REGISTER",

    //payment
    CERTIFICACIONDEAFILIACIONAPARTIDOPOLITICO_SET_DATA: "CERTIFICACIONDEAFILIACIONAPARTIDOPOLITICO_SET_DATA",
    CERTIFICACIONDEGOCEDESUSDERECHOSPOLITICOS_SET_DATA: "CERTIFICACIONDEGOCEDESUSDERECHOSPOLITICOS_SET_DATA",
    CONSTANCIADEINSCRIPCIONCOMODIRECTIVO_SET_DATA: "CONSTANCIADEINSCRIPCIONCOMODIRECTIVO_SET_DATA",
    CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA: "CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA",
    CONSTANCIADIRECTIVOMANDATARIOORGANIZACIONPOLITICA_SET_DATA: "CONSTANCIADIRECTIVOMANDATARIOORGANIZACIONPOLITICA_SET_DATA",
    CONSTANCIAAFILIACIONAPARTIDOSPOLITICOS_SET_DATA: "CONSTANCIAAFILIACIONAPARTIDOSPOLITICOS_SET_DATA",




    //sign up stepper
    SIGNUP_SET_DATA: "SIGNUP_SET_DATA",
    SOLICITUD_EMPADRONAMIENTO_SET_POSICIONES_DEPARTAMENTO_MUNICIPIO: "SOLICITUD_EMPADRONAMIENTO_SET_POSICIONES_DEPARTAMENTO_MUNICIPIO",
    SIGNUP_SET_FILES: "SIGNUP_SET_FILES",
    SIGNUP_SET_FILE_BY_INDEX: "SIGNUP_SET_FILE_BY_INDEX",
    SIGNUP_SET_STEP: "SIGNUP_SET_STEP",
    SIGNUP_SET_TOKEN_RECAPCHA: "SIGNUP_SET_TOKEN_RECAPCHA",
    SIGNUP_SET_RECAPTCHA_STATUS: "SIGNUP_SET_RECAPTCHA_STATUS",
    SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE: "SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE",
    SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE: "SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE",
    SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE: "SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE",
    SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE: "SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE",
    SIGNUP_SET_NOMBRE_TITULO_ARCHIVO: "SIGNUP_SET_NOMBRE_TITULO_ARCHIVO",
    LOGIN_CORREO_USUARIO: "LOGIN_CORREO_USUARIO",
    MENSAJE_CODIGO: "MENSAJE_CODIGO",
    CATALOGO_CREACION_USUARIOS: "CATALOGO_CREACION_USUARIOS",

    //habilitarUsuarioAfiliadoOP
    ASOCIAR_USUARIOS_SET_DATA_USUARIO_POR_ASOCIAR: "ASOCIAR_USUARIOS_SET_DATA_USUARIO_POR_ASOCIAR",
    ASOCIAR_USUARIOS_SET_STEP: "ASOCIAR_USUARIOS_SET_STEP",
    ASOCIAR_USUARIOS_SET_PERMISO_ICO: "ASOCIAR_USUARIOS_SET_PERMISO_ICO",
    ASOCIAR_USUARIOS_SET_TIPO_PERMISO_ICO: "ASOCIAR_USUARIOS_SET_TIPO_PERMISO_ICO",
    ASOCIAR_USUARIOS_SET_DEPARTAMENTO_ICO: "ASOCIAR_USUARIOS_SET_DEPARTAMENTO_ICO",
    ASOCIAR_USUARIOS_SET_MUNICIPIO_ICO: "ASOCIAR_USUARIOS_SET_MUNICIPIO_ICO",
    ASOCIAR_USUARIOS_SET_PERMISO_AFILIACION: "ASOCIAR_USUARIOS_SET_PERMISO_AFILIACION",

    //solicitudEmpadronamiento
    SOLICITUD_EMPADRONAMIENTO_SET_STEP: "SOLICITUD_EMPADRONAMIENTO_SET_STEP",
    SOLICITUD_EMPADRONAMIENTO_SET_LUGAR_RESIDENCIA: "SOLICITUD_EMPADRONAMIENTO_SET_LUGAR_RESIDENCIA",
    SOLICITUD_EMPADRONAMIENTO_SET_SOLICITUDES: "SOLICITUD_EMPADRONAMIENTO_SET_SOLICITUDES",
    SOLICITUD_EMPADRONAMIENTO_SET_DATOS_CIUDADANO_TABLE: "SOLICITUD_EMPADRONAMIENTO_SET_DATOS_CIUDADANO_TABLE",
    SOLICITUD_EMPADRONAMIENTO_SET_DATOS_RESIDENCIA: "SOLICITUD_EMPADRONAMIENTO_SET_DATOS_RESIDENCIA",
    SOLICITUD_EMPADRONAMIENTO_SET_ARRAY_LISTADO: "SOLICITUD_EMPADRONAMIENTO_SET_ARRAY_LISTADO",
    SOLICITUD_EMPADRONAMIENTO_SET_DATOS_SOLICITUD_RENAP: "SOLICITUD_EMPADRONAMIENTO_SET_DATOS_SOLICITUD_RENAP",
    SOLICITUD_EMPADRONAMIENTO_SET_DATOS_IDENTIDAD: "SOLICITUD_EMPADRONAMIENTO_SET_DATOS_IDENTIDAD",
    SOLICITUD_EMPADRONAMIENTO_SET_ES_RECHAZADO_O_EMPADRONADO: "SOLICITUD_EMPADRONAMIENTO_SET_ES_RECHAZADO_O_EMPADRONADO",
    SOLICITUD_EMPADRONAMIENTO_SET_RECHAZAR_SOLICITUD: "SOLICITUD_EMPADRONAMIENTO_SET_RECHAZAR_SOLICITUD",
    SOLICITUD_EMPADRONAMIENTO_SET_CUI_DPI: "SOLICITUD_EMPADRONAMIENTO_SET_CUI_DPI",
    SOLICITUD_EMPADRONAMIENTO_SET_ESTA_EMPADRONADO: "SOLICITUD_EMPADRONAMIENTO_SET_ESTA_EMPADRONADO",
    SOLICITUD_EMPADRONAMIENTO_SET_ESTA_FALLECIDO: "SOLICITUD_EMPADRONAMIENTO_SET_ESTA_FALLECIDO",
    SOLICITUD_EMPADRONAMIENTO_SET_EVENTO_RENAP: "SOLICITUD_EMPADRONAMIENTO_SET_EVENTO_RENAP",
    SOLICITUD_EMPADRONAMIENTO_SET_DATOS_EMPADRONADOR: "SOLICITUD_EMPADRONAMIENTO_SET_DATOS_EMPADRONADOR",
    SOLICITUD_EMPADRONAMIENTO_SET_DONT_ALLOW_CHANGE: "SOLICITUD_EMPADRONAMIENTO_SET_DONT_ALLOW_CHANGE",

    //CitasDeEmpadronamiento
    CITA_EMPADRONAMIENTO_SET_STEP: "CITA_EMPADRONAMIENTO_SET_STEP",
    CITA_EMPADRONAMIENTO_SET_LUGAR_RESIDENCIA: "CITA_EMPADRONAMIENTO_SET_LUGAR_RESIDENCIA",
    CITA_EMPADRONAMIENTO_SET_DATOS_CIUDADANO_TABLE: "CITA_EMPADRONAMIENTO_SET_DATOS_CIUDADANO_TABLE",
    CITA_EMPADRONAMIENTO_SET_DATOS_RESIDENCIA: "CITA_EMPADRONAMIENTO_SET_DATOS_RESIDENCIA",
    CITA_EMPADRONAMIENTO_SET_ARRAY_LISTADO: "CITA_EMPADRONAMIENTO_SET_ARRAY_LISTADO",
    CITA_EMPADRONAMIENTO_SET_DATOS_SOLICITUD_RENAP: "CITA_EMPADRONAMIENTO_SET_DATOS_SOLICITUD_RENAP",
    CITA_EMPADRONAMIENTO_SET_DATOS_IDENTIDAD: "CITA_EMPADRONAMIENTO_SET_DATOS_IDENTIDAD",
    CITA_EMPADRONAMIENTO_SET_ES_RECHAZADO_O_EMPADRONADO: "CITA_EMPADRONAMIENTO_SET_ES_RECHAZADO_O_EMPADRONADO",
    CITA_EMPADRONAMIENTO_SET_RECHAZAR_SOLICITUD: "CITA_EMPADRONAMIENTO_SET_RECHAZAR_SOLICITUD",
    CITA_EMPADRONAMIENTO_SET_CUI_DPI: "CITA_EMPADRONAMIENTO_SET_CUI_DPI",
    CITA_EMPADRONAMIENTO_SET_ESTA_EMPADRONADO: "CITA_EMPADRONAMIENTO_SET_ESTA_EMPADRONADO",
    CITA_EMPADRONAMIENTO_SET_ESTA_FALLECIDO: "CITA_EMPADRONAMIENTO_SET_ESTA_FALLECIDO",
    CITA_EMPADRONAMIENTO_SET_EVENTO_RENAP: "CITA_EMPADRONAMIENTO_SET_EVENTO_RENAP",
    CITA_EMPADRONAMIENTO_SET_DATOS_EMPADRONADOR: "CITA_EMPADRONAMIENTO_SET_DATOS_EMPADRONADOR",
    CITA_EMPADRONAMIENTO_SET_CITA_EN_PROCESO: "CITA_EMPADRONAMIENTO_SET_CITA_EN_PROCESO",

    //Solicitudes de Afiliacion
    SOLICITUD_AFILIACION_SET_AFILIADO: "SOLICITUD_AFILIACION_SET_AFILIADO",
    SOLICITUD_AFILIACION_DELETE_AFILIADO: "SOLICITUD_AFILIACION_DELETE_AFILIADO",
    SOLICITUD_AFILIACION_CLEAR_ALL: "SOLICITUD_AFILIACION_CLEAR_ALL",
    SOLICITUD_AFILIACION_SAVE_AFILIADO: "SOLICITUD_AFILIACION_SAVE_AFILIADO",

    /* Data OP */
    INFORMATION_OP_SET_DATA: "INFORMATION_OP_SET_DATA",
    
    //REPORTES ASAMBLEA 
     // SET_DATA_OF_POLITICALS_ORGANIZATIONS: "SET_DATA_OF_POLITICALS_ORGANIZATIONS",
      SET_DATA_OF_DEPARTMENTS: "SET_DATA_OF_DEPARTMENTS",
      SET_DATA_OF_MUNICIPALITIES: "SET_DATA_OF_MUNICIPALITIES",
};