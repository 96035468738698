import React, { Component } from 'react';
import ApiServices from 'service/ApiServices';
import LoadingIndicator  from 'common/LoadingIndicator';
import NotAuthorized from 'common/NotAuthorized';
import Title from 'component/TitleWithIcon';
import Alert from 'react-s-alert';
import {connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import {AccountCircle, Lock} from '@material-ui/icons/'
import Footer from 'page/Home/Footer2'
import {Container, Grid, Divider} from '@material-ui/core/';

import UserInformation from './UserInformation/UserInformation'
import VerificarIdentidad from './VerificarIdentidad'
import BotonOlvidarContraseña from './BotonOlvidarContraseña'

class UserProfile extends Component {     
    constructor(props) {
        super(props);
        this.state = {
            controller:'user',
            loading: false,
            authorized:true,
            checkAutorization:false,
            create:false,
            update:false,
            delete:false,
            rowData: [],
            data: [],
            currentUser: props.currentUser,
        }
        this.addRegister = this.addRegister.bind(this);
    }

    async addRegister(){ this.setState({create:true}); }
    
    componentDidMount() {
        // console.log(this.props.currentUser)
        // console.log(this.props.currentUser.id)
    }
    render() {
        if (this.state.checkAutorization ) return <LoadingIndicator/>
        if (!this.state.authorized &&  !this.state.loading){ return <NotAuthorized/>}
         
        return (
            <div>
                {this.state.loading ? <LoadingIndicator/> : '' }
                <div style={{backgroundColor: '#FFFFFF'}}>
                <Title title="Perfil del Usuario" icon={"/assets/PerfilUsuario.png"}/>
                </div><br/>
                <Container maxWidth="md">        
                    <Grid container >
                        <div style={{width: '100%', position: 'relative', overflow: 'auto', border: '1px solid  #cccccc', borderRadius: '20px', marginBottom:10,}} >
                            <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                <UserInformation DatosUsuario={this.props.currentUser}/>
                                <div style={{ bottom: 0, left: 0, width: "100%", height: 4, background: `rgb(2,0,36)`, background: `linear-gradient(344deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)`,}}></div>
                            </Grid>
                        </div>
                        <div style={{width: '100%', position: 'relative', overflow: 'auto', border: '1px solid  #cccccc', borderRadius: '20px',}} >
                            <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                <div style={{width: '100%', position: 'relative', overflow: 'auto'}} >
                                <Title title="Cambiar contraseña" icon={"/assets/cambiarContraseña.png"}/>
                                </div>
                            </Grid>                
                            <Grid style={{backgroundColor: '#FFFFFF'}} item xs={12} container direction="row" justify="center" alignItems="center">
                                <strong> <h3>Debes verificar tu identidad para poder continuar.</h3> </strong>
                            </Grid>                
                            <Grid style={{backgroundColor: '#FFFFFF'}} item xs={12} container direction="row" justify="center" alignItems="center">
                                <VerificarIdentidad />
                            </Grid>                
                            <Grid style={{backgroundColor: '#FFFFFF'}} item xs={12} container direction="row" justify="center" alignItems="center">
                                <BotonOlvidarContraseña />
                            </Grid>                
                            <div style={{ bottom: 0, left: 0, width: "100%", height: 4, background: `rgb(2,0,36)`, background: `linear-gradient(344deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)`,}}></div>
                        </div>
                    </Grid>
                </Container>
                <br/><br/><br/><Footer />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);