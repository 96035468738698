import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        margin: 5,
        //border: '2px solid #000000'
    },
    InstitutionTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
    },
    buttonMargin: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',  
    },    
}));