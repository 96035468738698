import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '100vh',
        backgroundColor: theme.palette.background.paper,
    },

    cardMainContainer: {
        padding: '25px'
    },

    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        padding: '10px 20px',
        filter: `drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))`,
    },

    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '40px 0px',
        justifyContent: 'center',
        columnGap: '60px'
    }
}));