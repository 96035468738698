import React from 'react';
import {useStyles} from './style';
import IconElement from 'component/IconElement';
import {AppBar, Toolbar, Typography} from '@material-ui/core'
import {ListAlt} from '@material-ui/icons';
import LinearStepper from './SignupForm/Stepper/';
import Footer from 'page/Home/Footer2';
import Title from 'component/TitleWithIcon';

const SignUp = (props) => {
    const classes = useStyles(props);
    return (
        <div >
            <div className={classes.signupContainer}>    
                <Title title="Formulario para solicitud de creación de usuario" icon={"/assets/CreacionUsuario.png"}/>
                <div className={classes.signupBox}>  
                    <LinearStepper/>
                </div>
            </div>
            <br /><br /><br /><Footer />
        </div>
    ) 
}
export default SignUp;