import React, { useRef, useState } from "react";
import { useStyles } from "./style";
import {Grid, CssBaseline, Paper, Typography, ButtonBase, Button} from "@material-ui/core";
import {connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import Alert from 'react-s-alert';
import './styles.css';

const UploadDocs = (props)=> {
    const classes = useStyles(props);
  
    const uploadImage = async (event,data) => {
        event.target.files=null
        const file_size = event.target.files[0];

        if(file_size){
            const  sizeInMB = (file_size.size / (1024*1024)).toFixed(2);
            if(sizeInMB > 25){
                Alert.error(data.titulo)
                props.SIGNUP_SET_NOMBRE_TITULO_ARCHIVO(data.titulo)
                props.SIGNUP_SET_FILE_BY_INDEX(data.id,null)
                if(data.id === 1){
                    props.SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE(true)
                }else if(data.id === 2){
                    props.SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE(true)
                }
            }else{
                var arr1 = file_size.type.split('/');
                if(data.id === 1){
                    props.SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE(false)
                    if(arr1[1] === 'jpg' || arr1[1] === 'jpeg' || arr1[1] === 'png'){
                        props.SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE(false)
                        const file = event.target.files[0];
                        const base64 = await convertBase64(file);   
                        props.SIGNUP_SET_FILE_BY_INDEX(data.id,base64);
                        if(base64)Alert.success("Archivo Cargado")                        
                    }else{
                        Alert.error('formato inválido para el archivo de DPI de la parte frontal')
                        props.SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE(true)
                    }
                }else if(data.id === 2){
                    props.SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE(false)            
                    if(arr1[1] === 'jpg' || arr1[1] === 'jpeg' || arr1[1] === 'png'){
                        props.SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE(false)
                        const file = event.target.files[0];
                        const base64 = await convertBase64(file);   
                        props.SIGNUP_SET_FILE_BY_INDEX(data.id,base64);
                        if(base64)Alert.success("Archivo Cargado")                        
                    }else{
                        Alert.error('formato inválido para el archivo de DPI de la parte trasera')
                        props.SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE(true)
                    }
                }               
            }
        }else{
            Alert.error("Seleccionar Archivo"+data.id)
            props.SIGNUP_SET_FILE_BY_INDEX(data.id,null)
        }        
    };

    const convertBase64 = (file) => {
        if(file){
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = () => { resolve(fileReader.result); };
                fileReader.onerror = (error) => { reject(error);    };
            });
        } 
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            {props.files.map((data, index) => (
                <Paper square className={classes.paper} key={index}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Grid item xs direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1">
                                        {data.text}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <input  multiple
                                        className="designButtonFile"
                                        type="file" 
                                        accept={data.type}                     
                                        onChange={(e) => {uploadImage(e,data) }}/>                                            
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <ButtonBase className={classes.image}>
                                <img    className={classes.img}
                                        alt="complex" src={data.imagePath}/>
                            </ButtonBase>
                        </Grid>                        
                    </Grid>
                </Paper>
            ))}
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (UploadDocs);