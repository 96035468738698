import React from 'react';
import { useStyles } from "./style";
import {Grid, AppBar, Toolbar, ButtonBase} from '@material-ui/core'

export default function Title(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={0}>
                <Toolbar variant="dense" className={classes.AppBar}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} >
                        {
                            props.icon && (
                        <ButtonBase className={classes.image}>
                            <img className={classes.img} alt="complex" src={props.icon} />
                        </ButtonBase>
                            )
                        }
                        <h1 className={classes.h1} variant="h5" color="initial">{props.title}</h1>   
                    </Grid>                    
                </Grid>                    
                </Toolbar>
            </AppBar> 
            <div className={classes.lineaDegradadaBottom}></div>
        </div>
    );
}