import React, {useState, useEffect} from 'react';
import {Container, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Divider, } from '@material-ui/core/';
import { useStyles } from "./style";
import AlertMaterial from '@material-ui/lab/Alert';
import Form from 'component/Form/FormTwoColumns';
import {connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import ApiServices from 'service/ApiServices';
import Alert from 'react-s-alert';
import Title from 'component/TitleWithIcon';
import {Lock, Done, Save} from '@material-ui/icons/';
import { useHistory } from "react-router-dom";
import Mensaje from '../../../component/MensajeElement'
import LoadingProgress from 'component/Loading'

function FormularioCambiarContraseña(props) {
    const classes = useStyles();
    const [controller,] = useState('user')
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mensaje, setMensaje] = useState({tipoError:'', tipoMensaje:''})

    const [apiErrors,setApiErrors] = React.useState([]);
    const [elements, setElements] = React.useState({
        codigo: { idelement: "codigo",  value:'', label: "Escriba el código", pattern:"^([0-9]){6,6}$", validators: ['required'], errorMessages:['Código numérico de 6 dígitos, sin espacios'], isError:false, elementType:'input' },
        password: { idelement: "password",  value:'', label: "Contraseña nueva", pattern:"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@#$!%*?&/-])[A-Za-z\\d$@#$!%*?&/-]\\S{6,20}$", validators: ['required'], errorMessages:['Alfanumérico y simbolos "$@#$!%*?&/-" de 6-20 caracteres, sin espacios.'], isError:false, elementType:'password' },
        repassword: { idelement: "repassword",  value:'', label: "Confirmar la nueva contraseña", pattern:"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@#$!%*?&/-])[A-Za-z\\d$@#$!%*?&/-]\\S{6,20}$", validators: ['required'], errorMessages:['Alfanumérico y simbolos "$@#$!%*?&/-" de 6-20 caracteres, sin espacios.'], isError:false, elementType:'password' },
    });  
    let history = useHistory();
    const [politicasContraseña, setPoliticasContraseña] = useState([
        {texto:'Minimo 6 caracteres.'},
        {texto:'Máximo 20 caracteres.'},
        {texto:'Al menos una letra mayúscula.'},
        {texto:'Al menos un caracter especial.'},
        {texto:'Al menos un número.'},
        {texto:'No espacios en blanco.'},
        // texto:'',
    ])

    const CambiarContraseña = async () =>{
        setLoading(true);
        let changePassword  = {email: props.currentUser.email, password: elements.password.value, code: elements.codigo.value};
        if(elements.password.value === elements.repassword.value){
            try{
                const response =  await ApiServices.userChangePasswordRequest.changePassword(changePassword);
                if (response.error!==null){
                    setOpen(true)
                    setLoading(false);
                    setMensaje({tipoError:'error', tipoMensaje:response.error.message})
                }else{
                    setLoading(false);
                    setOpen(true)
                    setMensaje({tipoError:'success', tipoMensaje:'¡BIEN! Contraseña actualizada correctamente.'})                        
                    elements.password.value="";
                    elements.repassword.value="";
                    setElements({ ...elements });              
                    history.push("/contraseñaActualizada",{})
                }   
            }catch(exception){
                (exception.status===404) ? Alert.warning("Intente de nuevo") : Alert.warning("Intente de nuevo ");
            }            
        }else{
            setOpen(true)
            setLoading(false);
            setMensaje({tipoError:'error', tipoMensaje:'Oops! Contraseñas no coinciden, por favor verificar.'})                                    
        }        
    }

    const Cancelar = () =>{
        history.push("/PerfilDelUsuario",{})
    }
    const ReenviarCodigo = async () =>{
        setLoading(true);
        try{
            const sendCode = Object.assign({}, {"email": props.currentUser.email});
            const response =  await ApiServices.userChangePasswordRequest.sendCode(sendCode);
            if (response.error!==null){
                setOpen(true)
                setMensaje({tipoError:'error', tipoMensaje:response.error.message})
                setLoading(false);
            }else{
                setOpen(true)
                setMensaje({tipoError:'success', tipoMensaje: response.data})                        
                setLoading(false);                
            }   
        }catch(exception){
            if(exception.status===404){ 
                Alert.warning("Intente de nuevo")
                setLoading(false);
            }else{
                Alert.warning("Intente de nuevo ");
                setLoading(false);
            }
        }                            
    }

    const [buttonList,setButtonList]= React.useState({
        "register":{"label":"Cambiar la Contraseña","callback":CambiarContraseña,"loading":false,"color":"#fff", "icon": <Save />, "width":"auto" ,"background":"#1C4E78"},
    });

    const handClose = (event, reason) =>{
        if (reason === 'clickaway') {return ;}
        setOpen(false)
    }

    useEffect(() => {
       // console.log('mensajecodigo: ', props.mensajeCodigo)
        if("Solicitud de cambio de clave activa, revise su correo electrónico" === props.mensajeCodigo){
            setOpen(true)
            setMensaje({tipoError:'error', tipoMensaje:props.mensajeCodigo})
        }else if("Código Enviado Correctamente "){
            setOpen(true)
            setMensaje({tipoError:'success', tipoMensaje:props.mensajeCodigo})
        }
    },[])
    return (
        <div >
            <Title title="Cambiar contraseña " icon={"/assets/cambiarContraseña.png"}/> <br/>
            <Container maxWidth="md" >
                <Grid container >
                    <div className={classes.colorComponente} >
                        <Grid item xs={12} container direction="row" justify="justify" alignItems="center" className={classes.espacio}> 
                            <Typography component="span" variant="body2" color="textPrimary">Elige una contraseña segura y no la utilices en otras cuentas.</Typography><br/><br/>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="justify" alignItems="center" className={classes.espacio}> 
                            <Typography component="span" variant="body2" color="textPrimary"> <strong>Política de Seguridad</strong></Typography><br/><br/>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                            <Typography component="span" variant="body2" color="textPrimary">
                                No uses una contraseña de otro sitio ni un nombre demasiado obvio, como el de tu mastoca, su nombre o fecha de nacimiento.
                                <div className={classes.root}>
                                    <List component="nav" aria-label="main mailbox folders">
                                        {politicasContraseña.map((data) => (
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <Done />
                                                </ListItemIcon>
                                                <ListItemText primary={data.texto}/>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Divider />        
                                </div>                    
                            </Typography><br/><br/>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                            <Mensaje type={mensaje.tipoError} content={mensaje.tipoMensaje} open={open} handClose={handClose} />
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="center" alignItems="center" className={classes.centrarBoton}>
                            <Form   
                                elements= {elements} 
                                buttonList={buttonList}
                                apiErrors={apiErrors} 
                                BotonCancelar={Cancelar}
                                BotonEnviarCodigo={ReenviarCodigo}
                            />
                        </Grid>
                        <div className={classes.lineaDegradadaBottom}></div>
                    </div>
                </Grid><br/>
                <LoadingProgress open={loading} text="Actualizando contraseña ..."/>
            </Container>        
        </div>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(FormularioCambiarContraseña);