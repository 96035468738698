import React from 'react';
import { Grid, Divider} from '@material-ui/core/';
import {useStyles} from './style';
import Title from 'component/TitleWithIcon';

const LandingUSer=(props)=> {
    const classes = useStyles(props);
    return (
        <div className={classes.header}>
            <Grid item xs={12}>
                <Grid>
                    <Title title="Bienvenido al Portal Web de Servicios del Tribunal Supremo Electoral" icon={"/assets/SkyIcon.svg"}/>
                    {/* <div className={classes.titleHeader}>Bienvenido al Portal Web de Servicios del Tribunal Supremo Electoral</div> */}
                    <Divider></Divider>
                    <div className={classes.description}>
                        En el menú se encuentran los servicios que se pueden gestionar a través de este Portal Web.  Favor seleccionar la gestión que desea realizar.
                    </div>
                </Grid>
            </Grid>
        </div> 
    )
}
export default (LandingUSer);