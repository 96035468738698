import React from "react";
import Footer from "page/Home/Footer2";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import IconElement from "component/IconElement/index";
import AlertDialog from "./FormElements/Dialog/index";
import AlertDialogMobile from "./FormElementsToMobile/Dialog/index";
import { Redirect } from "react-router-dom";
import FormTemplate from "./FormElements/index";
import FormTemplateMobile from "./FormElementsToMobile/index";
import { useStyles } from "./style";
import mapStateToProps from "./mapStateToProps";
import ButtonAnimation from "component/ButtonAnimation";
import mapDispatchToProps from "./mapDispatchToProps";
import CustomizedDialogs from './Dialog/index';

import { functions } from "constant";

const Login = (props) => {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles(props);

    const handleClose = () => {
        setOpen(false);
    };

    if (props.authenticated)
        return (
            <Redirect
                to={{
                pathname: "/",
                state: { from: props.location, authenticated: props.authenticated },
                }}
            />
        );
    else
        return (
            <>
                {/* {!functions.isMobile() ? ( */}
                    <div className={classes.loginContainer}>
                        <div className={classes.title}>PORTAL WEB TSE</div>
                        <div className={classes.loginBox}>
                            <div className={classes.img}>
                                <IconElement />
                            </div>
                            <h1 className={classes.titleLeft}> Bienvenido! </h1>
                            <FormTemplate />
                        </div>
                        <AlertDialog />
                    </div>
                {/* ):(
                    <>
                        <div className={classes.mobileContainer}>
                            <div className={classes.mobileHeaderDiv}>
                                <img alt="LOGO DEL TSE" src="assets/WhiteIcon.svg" className={classes.mobileIcon} />
                                <div className={classes.mobileTitleDivHeader}>Tribunal Supremo Electoral</div>
                            </div>

                            <FormTemplateMobile/>

                        </div>
                        <AlertDialogMobile/>
                    </>
                )} */}
                <div >
                    <Footer className={classes.footer}/>

                </div>
            </>
        );
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
