import React from 'react';
import {Container, Grid, Typography, Button, Icon} from '@material-ui/core/';
import { useStyles } from "./style";
import Title from 'component/TitleThree';
import { NavigateBefore} from '@material-ui/icons/';
import { useHistory } from "react-router-dom";

export default function MensajeConfirmacionCambioContraseña() {
    const classes = useStyles();
    let history = useHistory();

    const RegresarInicio = () =>{
        history.push("/",{})
    }

    return (
        <div>
            <Title title="Contraseña actualizado correctamente" icon="check_circle_outline"/> <br/>
            <Container maxWidth="md" className={classes.colorComponente}>
                <br/>
                <Grid container>
                    <Grid item xs={12} container direction="row" justify="center" alignItems="center"> 
                        <Icon style={{fontSize: 100 }} >check_circle_outline</Icon>
                    </Grid>
                    <Grid className={classes.espacio} item xs={12} container direction="row" justify="justify" alignItems="center"> 
                        <Typography component="span" variant="body2" color="textPrimary">
                            Su contraseña se ha cambiado correctamente. Deberá utilizarla la próxima vez que inicie sesión en el sistema.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid className={classes.espacio} item xs={12} container direction="row" justify="justify" alignItems="center"> 
                        <Typography component="span" variant="body2" color="textPrimary"> 
                            <strong>Seguridad</strong>
                        </Typography><br/><br/>
                    </Grid>
                    <Grid className={classes.espacio} item xs={12} container direction="row" justify="justify" alignItems="center">
                        <Typography component="span" variant="body2" color="textPrimary">
                            Cuide su cuenta de usuario. No comparta su contraseña con otras personas. Si realizan operaciones en el sistema con su cuenta
                            usted deberá responder por las mismas. Cambie su contraseña todas las veces que lo considere necesario.
                        </Typography><br/><br/>
                    </Grid>
                    <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                        <Button variant="contained" color="primary" onClick={RegresarInicio} startIcon={<NavigateBefore />} className={classes.colorBoton}
                        >Iniciar Sesión</Button>
                    </Grid>
                </Grid><br/>
            <div className={classes.lineaDegradadaBottom}></div>
            </Container>
        </div>
    );
}