import ApiGenericServicesData from "service/ApiGenericServicesData";

export default class ReportesGenerales extends ApiGenericServicesData {
    constructor() {
        super("reportesGenerales");
    }

    getInformeDetalladoAfRenun(data) {
        return this.customPOST("afiliadosORenunciasDetallado/", data);
    }

    getReporteComite(data) {
        return this.customPOST("reporteComitesEjecutivo/", data);
    }

}