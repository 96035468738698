import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
    root:{
        height: '100%',
        display: 'flex',
	},
	content:{
        flexGrow: 1,
        //padding: 20,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,        
       '@media (max-width:415px)':{ //mobile
            marginTop: 0,
        }
	},
    icon:{
        marginRight: 5,
    },
    title_text:{
       '@media (max-width:768px)':{ //ipad
            fontSize: '0.6rem'
        },
       '@media (max-width: 590px)':{ //mobile   
            display:'none',
        }
    },
    button: {
        '@media print':{
            display:'none',
        },
       '@media (max-width:768px)':{ //ipad
        marginLeft:5,
        minWidth:10,
        padding:0,
        },
       '@media (max-width: 442px)':{ //mobile   
            marginLeft:5,
            minWidth:10,
            padding:0,
        },
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        fontFamily: 'sans-serif',
        color:"black",
        letterSpacing: '0.1px',
       '@media (max-width:2560px)':{ //web
          fontSize:'0.8rem',
        },
       '@media (max-width:1440px)':{ //web
          fontSize:'0.8rem',
          minWidth: 200,
        },
       '@media (max-width:1025px)':{ //web
          fontSize:'0.8rem',
          minWidth: 200,
        },
       '@media (max-width:768px)':{ //ipad
            fontSize:'0.8rem',
            minWidth: 150,
            maxWidth: "75%",
        },
       '@media (max-width:426px)':{ //mobile
            fontSize:'0.6rem',
            maxWidth: "75%",
            minWidth: 150,
        },
       '@media (max-width:320px)':{ //mobile
            fontSize:'0.5rem',
            maxWidth: "75%",
        }
    },
    appIcon: {
        width: 35,
        height: 25, 
        paddingRight: 10
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: "linear-gradient(0deg, #1d5079 20%, #205690 62%)",
        boxShadow: "none",
    },
    appTitle: {
        color: '#fff',
        cursor: 'pointer',
        lineHeight: '60px',
        verticalAlign: 'middle',
        fontSize: '1.7em',
        fontWeight: '700',
        fontFamily: 'sans-serif',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        wordWrap: 'break-word',
        '&:hover':{
            textDecoration: 'none',
            color: 'white',
        }

    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
       '@media (min-width: 500px)':{
            display: 'none',
        }
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        boxShadow: '30px 0px 40px 17px rgba(55,56,55,0.45)',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        height: 'auto',
        padding: `40% 61% 7% 4%`,
        lineHeight: '1.1',
        fontSize: '0.96rem',
        fontWeight: 'bold',
        fontFamily: 'sans-serif',
        boxShadow: `0px -23px 10px 23px rgba(0,0,0,1)`,
        background: 'rgb(35,8,54)',
        background: `linear-gradient(0deg, #1d5079 20%, #205690 62%)`,
        color: 'white',
    },
    fab:{
        top: '1%',
        left: '4%',        
        position: 'absolute',
        border: '3px solid white',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    image: {
        width: '100%',
        borderRadius: '50%',
    },
    titleMenu:{
        fontFamily: 'sans-serif',
        color:"white",
        fontWeight: 'bold',
        fontSize:'1rem',
    },
    iconMenu:{
        top:'1%',
        left:'74%',
        width:'20%',
        color: 'rgba(255, 255, 255)',
        position: 'absolute',
    },
    toolbar:{
        minHeight: "62px",
        // "&>.MuiToolbar-regular" : {
        //     minHeight: "64px",
        // }  
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));