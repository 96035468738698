import React, { useState, useEffect, useCallback } from 'react';
import {Paper , Tabs, Tab, AppBar, Typography, Box} from '@material-ui/core/';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from "react-s-alert";
/********** COMPONENTS **********/
import Los40AfiliadosPorMunicipios from '../Los40AfiliadosPorMunicipios';
import InformacionGeneral from '../InformacionGeneral/index';
import DatosDeAfiliados from '../DatosDeAfiliaciones/index';
import LoadingSpinner from 'component/LoadingSpinner';
import AlertElement from 'component/AlertElement';
import NotAuthorized from 'common/NotAuthorized';
/********** SERVICES **********/
import ApiServices from 'service/ApiServices';
/********** REDUX **********/
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
/********** STYLES **********/
import { useStyles } from "./styles";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}> <Typography>{children}</Typography> </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const ScrollableTabsButtonForce = (props) => {
    const classes = useStyles();
    const dataOP = props.dataOP;
    const [elementAlertModal, setElementAlertModal] = useState({openAlert: false, titleAlert: '', typeAlert: '' }),
        [value, setValue] = useState(0),
        [authorized, setAuthorized] = useState(true),
        [loading, setLoading] = useState(false);

    const getOpData = useCallback(async () => {
        try {
            setLoading(true);
            const hasPermission = await ApiServices.userSecurity.hasPermission("informacionGeneralMiOrganizacionPolitica","list");
            if (hasPermission.error) {
                setAuthorized(false);
                setLoading(false);  
                setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: 'Permisos Insuficientes, Contacte con soporte.', 
                    typeAlert: 'error' });
            } else {        
                const response = await ApiServices.informacionGeneralMiOrganizacion.list();
                if (response.error !== null) {
                    setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: response.error.message, 
                        typeAlert: 'error' });
                }
                else if (response.data !== "") {
                    props.INFORMATION_OP_SET_DATA(response.data);
                }
                setLoading(false);
            }
        } catch (exception) {
            exception.status === 404 ? Alert.warning("Intente de nuevo") : Alert.warning("Intente de nuevo ");
            setLoading(false);
        }
    },[]);

    useEffect(() => {
        if((Object.keys(dataOP).length === 0)){
            getOpData();
        }
    },[]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /*  El método cierra el "AlertElement" que muestra los modales de alertas, se ejecuta desde el "AlertElement" */
    const handCloseModal = () => {
        setElementAlertModal({...elementAlertModal, openAlert: false})
    }
    

    if (!authorized && !loading) {
        return <NotAuthorized />;
    }

    return (
        <Paper  className={classes.root} elevation={0}>
            {loading && <LoadingSpinner open={loading}></LoadingSpinner>}
            {elementAlertModal.openAlert && <AlertElement typeAlert={elementAlertModal.typeAlert} openModal={elementAlertModal.openAlert} 
                title={elementAlertModal.titleAlert} handCloseModal={handCloseModal} />}
            <AppBar position="static" color="white" elevation={0}>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Información General" icon={<img className={classes.img} alt="complex" src={"/assets/InformacionGeneral.png"} />} {...a11yProps(0)} />
                    <Tab label="Listado de Afiliados" icon={<img className={classes.img} alt="complex" src={"/assets/ReporteListadoAfiliados.png"} />} {...a11yProps(1)} />
                    <Tab label="Total de afiliados de los 40" icon={<img className={classes.img} alt="complex" src={"/assets/Reporte.png"} />} {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            {/* Informacion general */}
            <TabPanel value={value}  index={0}>
                <InformacionGeneral/>
            </TabPanel>
            {/* Datos de afiliados */}
            <TabPanel value={value} index={1}>
                <DatosDeAfiliados/>
            </TabPanel>
            {/* los 40, afiliados por municipios */}
            <TabPanel value={value} index={2}>
                <Los40AfiliadosPorMunicipios/>
            </TabPanel>
        </Paper >
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollableTabsButtonForce);