import React from 'react';
import {Card,CardActions,CardContent, Icon, Typography,  Button} from '@material-ui/core';
import {ListAlt} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import {useStyles} from './style';
import {connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
import BotonPersonalizado from 'component/BotonNormal'

const UserRequestMessageSent = (props) => {
    const classes = useStyles();

    const NewRequest = () => {
        props.data.firstName = ""
        props.data.secondName= ""
        props.data.thirdName = ""
        props.data.firstLastName = ""
        props.data.secondLastName= ""
        props.data.marriedLastName=""
        props.data.cui = ""
        props.data.email=""
        props.data.birthDate= new Date()
        props.data.state_id = ""
        props.data.city_id = ""        
        props.SIGNUP_SET_STEP(0);                 
    }

    return (
        <Card className={classes.root} elevation={0}>
            <CardContent>
                <Icon style={{ color: grey[900], fontSize: 100 }} >check_circle_outline</Icon>
                <Typography variant="h3" gutterBottom>
                    ¡Solicitud creada correctamente!
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Su solicitud de usuario ha sido recibida. <br/> 
                    Dentro de los próximos 2 días hábiles se enviará, al correo que usted registró, indicando la aprobación o el rechazo de su solicitud.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    * En caso que no pueda visualizar en su bandeja de entrada, el correo enviado por el TSE, 
                    deberá verificar si el mismo se encuentra en la bandeja de SPAM.
                </Typography>
                <div className={classes.buttonMargin}>                    
                    <BotonPersonalizado title={"Nueva Solicitud"}  botonPersonalizado={true} function={NewRequest} icon={<ListAlt />}/>
                </div>
            </CardContent>
            <CardActions className={classes.InstitutionTitle}>
                <Typography variant="subtitle2">Tribunal Supremo Electoral, Guatemala, C.A.</Typography>
            </CardActions>
        </Card>
    );
}

export default connect(mapStateToProps, mapDispatchToProps) (UserRequestMessageSent);
