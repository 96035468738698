const initialState = {
    dataOP: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "INFORMATION_OP_SET_DATA":
            var newStateList = Object.assign({}, state);
            newStateList.dataOP = action.state.dataOP;
            return newStateList;
        default:
            return state;
    }
}

export default reducer;