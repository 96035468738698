import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    buttonSiguiente: {
        color: "#fff",
          background:
            "linear-gradient(130deg, rgb(6, 107, 189) 30%, rgb(30, 136, 229) 90%) repeat scroll 0% 0% ",
          display: "",
          justifyContent: "",
          //width: 180,
          margin: 20,
          textAlign: "center",
    },
    iconCancelar: {
        marginRight: 7,
    }
}));
