import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FileCopyOutlined } from '@material-ui/icons';
import { Button, Card } from '@material-ui/core';
import { connect } from 'react-redux';
import Alert from "react-s-alert";
/********** COMPONENTS **********/
import TitleWithIconBar from 'component/TitleWithIcon';
import LoadingSpinner from 'component/LoadingSpinner';
import AlertElement from 'component/AlertElement';
import Form from "component/Form/FormTwoColumns";
import NotAuthorized from 'common/NotAuthorized';
/********** SERVICES **********/
import ApiServices from 'service/ApiServices';
import { functions } from 'constant';
/********** REDUX **********/
import mapDispatchToProps from './mapDispatchToProps';
import mapStateToProps from './mapStateToProps';
/********** STYLES **********/
import { useStyles } from './style';


const ReportesAsamblea = (props) => {
    /********** VARIABLES **********/
    const dataOPRedux = props.dataOP;
    const classes = useStyles();
    const controller = 'reportesGenerales';
    const dataOpRef = useRef(dataOPRedux);
    const tipoOrganizacionNac = [
        {id: 1, name: "MUNICIPAL"}, 
        {id: 2, name: "DEPARTAMENTAL"}, 
        {id: 3, name: "NACIONAL"}
    ];
    const [elementAlertModal, setElementAlertModal] = useState({openAlert: false, titleAlert: '', typeAlert: '' }),
    [loadingSpinner, setLoadingSpinner] = useState({openLoading: false, message: ''}),
    [authorized, setAuthorized] = useState(true),
    [elements, setElements] = useState({        
        tipoOrganizacion: {
            idelement: "tipoOrganizacion", value: null, label:"Seleccione el tipo de Órgano Político", pattern: "^[0-9]*$", 
            validators: 'required', isError: false, errorMessages: ["Debe seleccionar el tipo de órgano"], 
            elementType: 'autocompleteV2', list: tipoOrganizacionNac, showSelectAutoComplete: true, disabled: false, handler: handleChangeTypeOrganization 
        },
        idDepartamento: {
            idelement: "idDepartamento", value: null , label: "Seleccione el departamento *", pattern:"^[0-9]*$", validators: '',
            errorMessages:['Seleccione el departamento'], isError:false, elementType:'autocompleteV2', list: [], handler: undefined,
            showSelectAutoComplete: false, disabled: false
        },
        idMunicipio: { 
            idelement: "idMunicipio", value: null , label: "Seleccione el municipio *", pattern:"^[0-9]*$", validators: '',
            errorMessages:['Seleccione el municipio'], isError:false, elementType:'autocompleteV2', list: [], showSelectAutoComplete: false, disabled: false
        },
    });

/********** FUNCTIONS **********/
    const getOpData = useCallback(async () => {
        try {
            setLoadingSpinner({...loadingSpinner, openLoading: true, message: 'Obteniendo datos de la Organización...'});
            const hasPermission = await ApiServices.userSecurity.hasPermission("informacionGeneralMiOrganizacionPolitica","list");
            if (hasPermission.error) {
                setAuthorized(false);
                setLoadingSpinner({...loadingSpinner, openLoading: false})
                setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: 'Permisos Insuficientes, Contacte con soporte.', 
                    typeAlert: 'error' });
            } else {        
                const response = await ApiServices.informacionGeneralMiOrganizacion.list();
                if (response.error !== null) {
                    setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: response.error.message, 
                        typeAlert: 'error' });
                }
                else if (response.data !== "") {
                    props.INFORMATION_OP_SET_DATA(response.data);
                }
                setLoadingSpinner({...loadingSpinner, openLoading: false})
            }
        } catch (exception) {
            exception.status === 404 ? Alert.warning("Intente de nuevo") : Alert.warning("Intente de nuevo ");
            setLoadingSpinner({...loadingSpinner, openLoading: false})
        }
    },[]);

    useEffect(() => {
        if((Object.keys(dataOPRedux).length === 0)){
            getOpData();
        }
    },[]);

    useEffect(() => {
        getDepartamentData();
    },[])

    useEffect(() => {
        dataOpRef.current = dataOPRedux;
    },[dataOPRedux]);

    /* Función para obtener el listado de las organizaciones políticas para mostrarlas en el select */
    const getDepartamentData = useCallback(async () => {
        try {
            setLoadingSpinner({...loadingSpinner, openLoading: true, message: 'Obteniendo datos de los Departamentos...'});
            let response = await ApiServices.departamentos.listRegisterPublic();
            if(response.error !== null){
                setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: response.error.message, 
                    typeAlert: 'error' });
            } else {
                let dataDepartament = response.data.filter((_, index) => ![22, 23, 24].includes(index));
                let newDataDepartament = [{id: 0, name: 'TODOS LOS DEPARTAMENTOS'}, ...dataDepartament];
                elements.idDepartamento.list = newDataDepartament;
            }
            setLoadingSpinner({...loadingSpinner, openLoading: false});
        } catch (exception) {
            setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'error', 
                titleAlert: 'Error al obtener los datos de Departamentos' + exception + ', consulte con soporte.'});
            setLoadingSpinner({...loadingSpinner, openLoading: false});
        }
    },[]);

    /* Función para habilitar los Inputs de Departamento y Municipio según el tipo de Organización */
    function handleChangeTypeOrganization(){
        let newElements = Object.assign({}, elements);
        /* Reportes Municipal */
        if (elements.tipoOrganizacion.value.id === 1){
            newElements.idDepartamento.handler = handlerDepartament;
            newElements.idDepartamento.showSelectAutoComplete = true;
            newElements.idMunicipio.showSelectAutoComplete = true;
            newElements.idDepartamento.value = null;
            newElements.idMunicipio.value = null;
            newElements.idMunicipio.list = [];
        /* Reportes Departamentales */
        } else if (elements.tipoOrganizacion.value.id === 2){
            newElements.idDepartamento.handler = undefined;
            newElements.idDepartamento.showSelectAutoComplete = true;
            newElements.idMunicipio.showSelectAutoComplete = false;
            newElements.idDepartamento.value = null;
            newElements.idMunicipio.value = null;
            newElements.idMunicipio.list = [];
        /* Reportes Nacionales */
        } else if (elements.tipoOrganizacion.value.id  === 3 || elements.tipoOrganizacion.value.id === null){
            newElements.idDepartamento.showSelectAutoComplete = false;
            newElements.idMunicipio.showSelectAutoComplete = false;
            newElements.idDepartamento.value = null;
            newElements.idMunicipio.value = null;
            newElements.idMunicipio.list = [];
        }
        setElements(newElements);
    }

    /* ESTE CODIGO DEBE OPTIMIZARSE PARA UTILIZAR REDUX Y EVITAR OBTENER MUNICIPIOS CADA VEZ */
    /* Función que se ejecuta cuando se detecta un cambio en el Autocomplete de Departamentos */
    async function handlerDepartament({target}){
        if(target.value === ''){
            elements.idMunicipio.list = [];
            elements.idMunicipio.value = null;
        } else {
            //const municipioStore = municipiosRef.current;
            elements.idMunicipio.list = [];
            elements.idMunicipio.value = null;
            getMunicipalitiesData(target?.value);
            /* if (municipioStore && municipioStore.length > 0) {
                const currentMunicipality = municipioStore?.filter((value) => 
                    Number(value.departamentoId) === Number(target?.value));            
                if(currentMunicipality?.length > 0) {
                    let newMunicipalities = [{id: 0, name: 'TODOS LOS MUNICIPIOS'}, ...currentMunicipality];
                    elements.idMunicipio.list = newMunicipalities;
                } else {
                    getMunicipalitiesData(target?.value);
                }
            } else {
                getMunicipalitiesData(target?.value);
            } */
        }
    }

    /* Función para obtener los municipios según el departamento que hayamos seleccionado */
    const getMunicipalitiesData = async (idDepartament) => {
        try {
            setLoadingSpinner({...loadingSpinner, openLoading: true, message: 'Obteniendo datos de los Municipios...'});
            if(idDepartament === 0){
                let newMunicipalities = [{id: 0, name: 'TODOS LOS MUNICIPIOS'}];
                elements.idMunicipio.list = newMunicipalities;
            } else {
                ApiServices.municipios.searchCriteria.clear();
                ApiServices.municipios.searchCriteria.setOperator("and");
                ApiServices.municipios.searchCriteria.addEquals("id.departamentoId", idDepartament);
                ApiServices.municipios.setIsPublic(true);
                let response = await ApiServices.municipios.listRegisterCriteria();
                if(response.error !== null){
                    setElementAlertModal({...elementAlertModal, openAlert: true, titleAlert: response.error.message, 
                        typeAlert: 'error' });
                } else {
                    var datos = response.data;
                    let municipalitiesArray = [];
                    Object.keys(datos).forEach((element, key, _array) => {
                        municipalitiesArray.push({
                            id: datos[element].id.id,
                            name: datos[element].name,
                          });
                    });
                    let newMunicipalities = [{id: 0, name: 'TODOS LOS MUNICIPIOS'}, ...municipalitiesArray];
                    elements.idMunicipio.list = newMunicipalities;
                }
            }
            setLoadingSpinner({...loadingSpinner, openLoading: false});
        } catch (exception) {
            setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'error', 
                titleAlert: 'Error al obtener los datos de Municipios' + exception + ', consulte con soporte.'});
            setLoadingSpinner({...loadingSpinner, openLoading: false});
        }
    }

    /* Función para obtener reportes según los parametros seleccionados */
    async function generateReport(){
        try {
            if(elements.tipoOrganizacion.value === null){
                setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'warning', 
                    titleAlert: 'Seleccione primero todos los campos.'});
            } else {
                const valueOP = dataOpRef.current;
                setLoadingSpinner({ ...loadingSpinner, openLoading: true, message: 'Generando reporte...' }); 
                const hasPermission = await ApiServices.userSecurity.hasPermission(controller, "reporteComitesEjecutivo");
                if (hasPermission.error) {
                    setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'warning', 
                        titleAlert: 'Permisos Insuficientes'});
                    setLoadingSpinner({ ...loadingSpinner, openLoading: false});
                } else {
                    let data = {tipoReporte: 2, idOp: valueOP.idOrganizacion, esCompleto: 1, 
                        tipo: elements.tipoOrganizacion.value.id, nombrePartido: valueOP.name};
                    let titleDocument = 'Reporte OPASAM ' + valueOP.name + ' ' +  elements.tipoOrganizacion.value.name;

                    if(elements.tipoOrganizacion.value.id === 1){
                        /* REPORTE MUNICIPAL */
                        if(elements.idDepartamento.value === null || elements.idMunicipio.value === null){
                            setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'warning', 
                                titleAlert: 'Seleccione primero todos los campos.'});
                            setLoadingSpinner({ ...loadingSpinner, openLoading: false});                 
                            return
                        } else {
                            data.idDepartamento = elements.idDepartamento.value.id === 0 ? null : 
                                elements.idDepartamento.value.id;
                            data.idMunicipio = elements.idMunicipio.value.id === 0 ? null : 
                                elements.idMunicipio.value.id;
                        }
                    } else if (elements.tipoOrganizacion.value.id === 2) {
                        /* REPORTE DEPARTAMENTAL */
                        if(elements.idDepartamento.value === null){
                            setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'warning', 
                                titleAlert: 'Seleccione primero todos los campos.'});
                            setLoadingSpinner({ ...loadingSpinner, openLoading: false});                 
                            return
                        } else {
                            data.idDepartamento = elements.idDepartamento.value.id === 0 ? null : 
                                elements.idDepartamento.value.id;
                            data.idMunicipio = null;
                        }
                    } else if (elements.tipoOrganizacion.value.id === 3) {
                        data.idDepartamento = null;
                        data.idMunicipio = null;
                    }
                    let response = await ApiServices.reportesGenerales.getReporteComite(data);
                    if(response.error !== null){
                        var messageError = JSON.parse(response.error.message);
                        setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'error', titleAlert: messageError.message});
                    } else {
                        functions.downloadPDFFromStringBase64(response.data.data, titleDocument);
                    }  
                    setLoadingSpinner({ ...loadingSpinner, openLoading: false});                 
                }
            }
        } catch (exception) {
            setElementAlertModal({ ...elementAlertModal, openAlert: true, typeAlert: 'error', 
                titleAlert: 'Error al generar el reporte' + exception + ', consulte con soporte.'});
            setLoadingSpinner({ ...loadingSpinner, openLoading: false});
        }
    }

    /*  El método cierra el "AlertElement" que muestra los modales de alertas, se ejecuta desde el "AlertElement" */
    const handCloseModal = () => {
        setElementAlertModal({...elementAlertModal, openAlert: false})
    }
    
/********** RENDER **********/
    if (!authorized && !loadingSpinner.openLoading) {
        return <NotAuthorized />;
    }

    return (
        <div className={classes.root}>
            {loadingSpinner.openLoading && <LoadingSpinner open={loadingSpinner.openLoading} message={loadingSpinner.message}/>}
            {elementAlertModal.openAlert && <AlertElement typeAlert={elementAlertModal.typeAlert} openModal={elementAlertModal.openAlert} 
                title={elementAlertModal.titleAlert} handCloseModal={handCloseModal} />}
            <TitleWithIconBar title="Reporte de Comite Ejecutivo" icon={"/menu/Constancia.png"} />
            <div className={classes.cardMainContainer}>
                <Card className={classes.cardContainer}>
                    <div className={classes.searchContainer}>
                        <Form elements={elements} />
                    </div>           
                    <div className={classes.buttonContainer}>
                        <Button className={classes.buttonSearch} onClick={generateReport} variant='outlined' startIcon={<FileCopyOutlined/>}>
                            BUSCAR
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportesAsamblea);